/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > loadTranslatorOptsGeneric
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import isNil from 'lodash/isNil'

/**
 * Action to load translator options.
 *
 * @param {Object} context - Current context of the Vuex module.
 * @param {Object} payload - Contains necessary values usable within the method.
 * @param {string} [payload.translator_name] - Filter for specific name(s).
 * @param {number|string} [payload.translator_id] - Filter for specific translator ID(s).
 * @returns {Promise}
 */
export default function (context, payload = {}) {
  // Prevent request if no translator_id or translator_name
  if (
    (isNil(payload.translator_name) || payload.translator_name === '') &&
    (isNil(payload.translator_id) || payload.translator_id === '' || payload.translator_id === 0)
  ) {
    console.warn('prevent load all translator data.')
    // Return a resolved promise with a default/empty response
    return Promise.resolve(context.state.defaultResponse)
  }

  // Build query parameters (removing `all: true`)
  let queryParams = {
    'fields[users]': 'id,name',
    'sort': 'name'
  }

  // Apply filters if provided
  if (!isNil(payload.translator_name) && payload.translator_name !== '') {
    queryParams['filter[name]'] = payload.translator_name
  }
  if (!isNil(payload.translator_id) && payload.translator_id !== '' && payload.translator_id !== 0) {
    queryParams['filter[id]'] = payload.translator_id
  }

  // Prepare for pagination
  let nextPageUrl = API.TRANSLATORS
  let allTranslators = []

  return new Promise((resolve, reject) => {
    const loadData = async () => {
      let finalResponse = null

      try {
        while (nextPageUrl) {
          // Convert `http://` to `https://` if necessary
          if (nextPageUrl.startsWith('http://')) {
            nextPageUrl = nextPageUrl.replace('http://', 'https://')
          }

          // Fetch the current page
          const response = await axiosInstance.get(nextPageUrl, {
            params: queryParams
          })

          // Keep a reference to this response so we can mutate it with all pages
          finalResponse = response

          // Accumulate the current page's translators
          const currentUsers = response?.data?.data?.translators || []
          allTranslators = allTranslators.concat(currentUsers)

          // Determine if there's another page
          nextPageUrl = response?.data?.meta?.pagination?.links?.next || null
        }

        // Mutate the final response to include the combined list of translators
        if (finalResponse) {
          finalResponse.data = finalResponse.data || {}
          finalResponse.data.data = finalResponse.data.data || {}
          finalResponse.data.data.translators = allTranslators
        }

        // Resolve the promise with the final mutated response
        resolve(finalResponse)
      } catch (error) {
        console.error('Error loading translator options:', error)
        reject(error)
      }
    }

    // Kick off the data loading
    loadData()
  })
}
