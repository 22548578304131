import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import { CUSTOMER } from '@/modules/constants/userType'
export default function (context, payload = {}) {
  const url = API.USERS
  const method = 'GET'

  const include = ['customer_data.department.company'].join(',')
  const sort = payload.sort || '-created_at'

  let options = {
    query_params: {
      include,
      sort,
      'per_page': 15,
      'page': payload.page,
      'filter[type]': CUSTOMER,
      'filter[municipality]': payload.municipality_id,
      'filter[enabled]': payload.filter.status == 'active' ? 1 : 0,
      'filter[created_date_range]': payload.filter.dateRange
    }
  }

  let search = {}
  if (payload.filter.filter_query) {
    const key = `filter[${payload.filter.filter_by}]`
    search[key] = payload.filter.filter_query
  }

  options.query_params = { ...options.query_params, ...search }

  context.commit('setLoading', true)
  return axiosInstance({
    url,
    method,
    params: options.query_params
  })
    .then((response) => {
      context.commit('setUsers', response.data.data.users)
      context.commit('setPagination', response.data.meta.pagination)
    })
    .catch((error) => {
      return error
    })
    .finally(() => {
      context.commit('setLoading', false)
    })
}
