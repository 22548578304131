<template>
  <el-config-provider :locale="localeForElementPlus">
    <div id="app" :data-verbiage-loaded-global="isVerbiageLoaded">
      <template v-if="isTenantLoaded">
        <!-- Fullscreen loader -->
        <fullscreen-loader :visible="$store.getters['fullscreenLoader/visible']" :message="`Loading Application...`" />
        <el-scrollbar height="100vh" :always="true">
          <component :is="layout">
            <router-view />
          </component>
        </el-scrollbar>
      </template>
      <fullscreen-loader v-else :visible="true" :message="tenantLoadingMessage" />
      <!-- iframe to send message to another domain/iframeSrc -->
      <login-non-login-env-iframe v-if="!isLocalHost" ref="non-login-iframe" />
    </div>
  </el-config-provider>
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex'
import moment from 'moment-timezone'
import { configureTheme } from '@dtolk/digitaltolk-ui'
import FullscreenLoader from './modules/auth/components/displays/FullscreenLoader'
import LoginNonLoginEnvIframe from '@/components/partials/LoginNonLoginEnvironmentIframe'
// import { showSmallErrorNotif } from '@/modules/helpers/notifications'
import { loadTenantScripts, loadTenantStyles } from '@/modules/helpers/multiTenancy'
import { useHead } from '@unhead/vue'
import i18nClient, { loadLocaleMessages } from '@/locales/i18nClient.js'
import { ElConfigProvider } from 'element-plus'
import da from 'element-plus/dist/locale/da.mjs'
import sv from 'element-plus/dist/locale/sv.mjs'

const def = 'default'

export default {
  /*
  |--------------------------------------------------------------------------
  | Component > imported components
  |--------------------------------------------------------------------------
  */
  components: {
    FullscreenLoader,
    LoginNonLoginEnvIframe,
    ElConfigProvider
  },

  data() {
    return {
      tenantLoadingMessage: 'Loading Tenant...'
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > computed
  |--------------------------------------------------------------------------
  */
  computed: {
    ...mapGetters('email', ['getTotalUnreadInboxCount', 'getTotalUnreadNotificationsCount']),
    ...mapGetters('auth', ['isUserAuthenticated', 'isUserAdmin']),
    ...mapGetters('multiTenancy', ['isTenantLoaded', 'getTenantConfig']),

    ...mapState('auth', ['language']),

    localeForElementPlus() {
      switch (this.language) {
        case 'se':
          return sv
        case 'da':
          return da
        default:
          return null
      }
    },

    layout() {
      return (this.$route.meta.layout || def) + '-layout'
    },
    totalNotificationsCount() {
      return this.getTotalUnreadInboxCount + this.getTotalUnreadNotificationsCount
    },
    hasNotifications() {
      return this.getTotalUnreadInboxCount && this.getTotalUnreadNotificationsCount && this.totalNotificationsCount > 0
    },
    isVerbiageLoaded() {
      let isLoaded = this.$t('is_verbiage_loaded') === 'true' ? true : false

      // if (!isLoaded) {
      //   console.log('i18nClient.locale', i18nClient.locale)
      //   const verbiage = localStorage.getItem(`app_verbiages_terms_${i18nClient.locale}`)
      //   if (verbiage && Object.keys(verbiage).length > 0) {
      //     isLoaded = true
      //   }
      // }

      if (isLoaded) {
        this.hideVerbiageLoader()
      }

      return isLoaded
    },

    isLocalHost() {
      return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
    }
  },
  watch: {
    isUserAuthenticated: {
      deep: true,

      handler(v) {
        this.checkUserAuth(v)
        if (v) {
          this.initPusher()
        }
      }
    },
    isUserAdmin: {
      // switch between admin/customer theme
      handler(newValue) {
        if (newValue === true) {
          document.body.style.setProperty('--app-primary-color', '#366fae')
          document.body.style.setProperty('--app-primary-color-rgb', '#366fae')
          configureTheme({
            colors: {
              primaryColor: '#366fae',
              primaryColorHover: '#366fae',
              secondaryColor: '#366fae',
              secondaryColorHover: '#366fae'
            }
          })
        } else if (this.isTenantLoaded) {
          this.setTenantColors()
        }
      },
      immediate: true
    },
    isTenantLoaded: {
      handler(v) {
        if (v) {
          this.applySettingsAfterTenantIsLoaded()
        }
      },
      immediate: true
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > mounted
  |--------------------------------------------------------------------------
  */
  async mounted() {
    this.clearCache()

    this.disableConsoleLog()
    this.fetchTenantInfo()

    this.pageMeta()
  },

  /*
  |--------------------------------------------------------------------------
  | Component >
  |--------------------------------------------------------------------------
  */
  methods: {
    ...mapActions('multiTenancy', ['loadTenant', 'loadVersion']),
    ...mapActions('auth', ['initPusher', 'initToken']),
    ...mapActions('booking', ['initializeLanguages']),
    ...mapMutations('multiTenancy', ['setTenantDomain']),
    ...mapMutations('auth', ['loadLanguage']),
    // Disable logs in production
    disableConsoleLog() {
      console.log('Active environment...', process.env.NODE_ENV)
      if (process.env.NODE_ENV === 'production') {
        console.log = function () {}
        console.groupCollapsed = function () {}
        console.groupEnd = function () {}
      }
    },

    clearCache() {
      const cacheVersion = localStorage.getItem('cache-version')
      console.info('Current cache version...', cacheVersion)

      if (!cacheVersion || !cacheVersion === '1') {
        console.info('Clearing browser cache..')

        this.clearCookies()
        this.clearLocalStorage()
        this.clearSessionStorage()

        localStorage.setItem('cache-version', '1')
      }
    },

    clearCookies() {
      document.cookie.split(';').forEach(function (c) {
        document.cookie = c.replace(/^ +/, '').replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`)
      })
    },

    clearLocalStorage() {
      localStorage.clear()
    },

    clearSessionStorage() {
      sessionStorage.clear()
    },

    /**
     * @return {void}
     */
    showVerbiageLoader() {
      // console.log('Showing the fullscreen loader ..............')
      this.$store.dispatch('fullscreenLoader/show')
    },

    /**
     * @return {void}
     */
    hideVerbiageLoader() {
      // console.log('Hiding the fullscreen loader.........')
      this.$store.dispatch('fullscreenLoader/hide')
    },

    setLocaleVerbiage(locale) {
      const verbiage = localStorage.getItem(`app_verbiages_terms_${locale}`)
      this.$i18n.setLocaleMessage(locale, JSON.parse(verbiage))
      this.$i18n.locale = locale
    },

    pageMeta() {
      useHead({
        meta: [
          {
            charset: 'utf-8'
          },
          {
            'http-equiv': 'X-UA-Compatible',
            'content': 'IE=edge'
          },
          // {
          //   name: 'viewport',
          //   content: 'width=device-width, initial-scale=1'
          // },
          {
            name: 'google-site-verification',
            content: 'MnPFTasDurWXm6UJsdLz_SFtcn9RXUtguHu-PZ7BgAM'
          }
        ],
        script: []
      })
    },
    checkUserAuth(v) {
      if (!this.isLocalhost) return

      if (v) {
        // login non-loggedin user
        this.$refs['non-login-iframe'].userLoginFromNonLoginEnvironment()
      } else {
        // logout
        this.$refs['non-login-iframe'].userLogOutFromNonLoginEnvironment()
      }
    },
    extractDomainFromURL() {
      const url = window.location.href
      const splittedURL = url.split('/')
      let domain = splittedURL[2]
      if (splittedURL.length > 3) {
        const subPath = splittedURL[3]
        if (/^[a-zA-Z]+$/.test(subPath)) {
          domain = `${domain}/${subPath}`
        }
      }
      console.log('Setting tenant DOMAIN...', domain)
      this.setTenantDomain(domain)
      return domain
    },
    fetchTenantInfo() {
      const domain = this.extractDomainFromURL()
      this.loadTenant({ domain }).then((r) => {
        if (r instanceof Error) {
          this.tenantLoadingMessage = `Error loading tenant: ${r.message}`
          // showSmallErrorNotif(`Error loading tenant. ${r.message}`)
        }
        this.$store.dispatch('auth/initToken')
      })
    },
    setTenantColors() {
      if (this.getTenantConfig) {
        // set tenant colors
        document.body.style.setProperty('--app-primary-color', this.getTenantConfig.primary_color)
        document.body.style.setProperty('--app-primary-color-rgb', this.getTenantConfig.primary_color)
        configureTheme({
          colors: {
            primaryColor: this.getTenantConfig.primary_color,
            primaryColorHover: this.getTenantConfig.secondary_color,
            secondaryColor: this.getTenantConfig.secondary_color,
            secondaryColorHover: this.getTenantConfig.secondary_color
          }
        })
      }
    },
    setFavicon() {
      if (this.getTenantConfig) {
        let favicon = document.querySelector('link[rel="icon"]')
        if (!favicon) {
          favicon = document.createElement('link')
          favicon.setAttribute('rel', 'icon')
          favicon.setAttribute('type', 'image/png')
          document.head.appendChild(favicon)
        }
        favicon.setAttribute('href', this.getTenantConfig.extra_data.favicon)
      }
    },
    async applySettingsAfterTenantIsLoaded() {
      const self = this

      console.log('Loading tenant settings', this.getTenantConfig)

      if (this.isUserAuthenticated) {
        this.$store.dispatch('auth/initToken')
        this.initPusher()
      }

      document.title = this.getTenantConfig.name
      this.setFavicon()
      this.setTenantColors()
      this.loadLanguage()
      this.initializeLanguages()

      this.loadVersion()

      moment.tz.setDefault(this.$store.state.auth?.user?.timezone ?? this.getTenantConfig.timezone)

      let localesToLoad = ['en']

      // set tenant locales
      localesToLoad = [this.getTenantConfig.default_locale]
      localesToLoad = [
        ...localesToLoad,
        ...this.getTenantConfig.localization_languages.slice(0, 2).map((lang) => lang.value)
      ]

      await loadLocaleMessages(i18nClient, i18nClient.locale, localesToLoad)

      // Show the loader if verbiage isn't loaded
      if (!this.isVerbiageLoaded) {
        self.showVerbiageLoader()
      }

      // document.addEventListener('verbiageTermsLoadingFinishedEvent', function () {
      //   let storedLocale = localStorage.getItem('app_language')
      //   storedLocale = !isNil(storedLocale) ? storedLocale : 'se'

      //   self.setLocaleVerbiage(storedLocale)
      //   self.hideVerbiageLoader()
      // })

      // // for non-loggedin
      // this.checkUserAuth(this.isUserAuthenticated)

      loadTenantStyles()
      loadTenantScripts()
    }
  }
}
</script>

<style lang="scss" scoped>
#app .app-layout {
  display: none;
}
#app[data-verbiage-loaded-global='true'] .app-layout {
  display: block;
}
</style>
