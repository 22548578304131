import { BOOKING_TIME_SLOT } from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

export default function (context, payload = {}) {
  const baseUrl = BOOKING_TIME_SLOT

  // Build the query params (omitting `all: true`)
  let queryParams = {
    'include': 'translator.future_assigned_bookings,translator.translator_unavailable_times',
    'sort': '-translator_id',
    'filter[is_valid]': true,
    'enable_error_notif': false,
    ...payload
  }

  return new Promise((resolve, reject) => {
    const loadData = async () => {
      let nextPageUrl = baseUrl
      let allTimeSlots = []
      let finalResponse = null

      try {
        // Loop until there's no next page
        while (nextPageUrl) {
          // Convert http:// to https:// if necessary
          if (nextPageUrl.startsWith('http://')) {
            nextPageUrl = nextPageUrl.replace('http://', 'https://')
          }

          // Fetch the current page
          const response = await axiosInstance.get(nextPageUrl, {
            params: queryParams
          })

          finalResponse = response

          // Accumulate the current page's time slots
          const currentSlots = response?.data?.data?.booking_time_slots ?? []
          allTimeSlots = allTimeSlots.concat(currentSlots)

          // Check if there's another page
          nextPageUrl = response?.data?.meta?.pagination?.links?.next || null
        }

        // Mutate finalResponse to include the combined time slots
        if (finalResponse) {
          finalResponse.data = finalResponse.data || {}
          finalResponse.data.data = finalResponse.data.data || {}
          finalResponse.data.data.booking_time_slots = allTimeSlots
        }

        resolve(finalResponse)
      } catch (error) {
        console.error('Error fetching booking time slots:', error)
        reject(error)
      }
    }

    // Start the data loading
    loadData()
  })
}
