/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > loadDepartmentOpts
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import isNil from 'lodash/isNil'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

export default function (context, payload = {}) {
  context.commit('auth/setDepartmentOptsLoading', true, { root: true })

  // Build query params (removing `all: true`)
  let queryParams = {
    'fields[departments]': 'id,name'
  }
  if (!isNil(payload.company_id)) {
    queryParams['company_id'] = payload.company_id
  }

  const url = `${API.DEPARTMENTS}-get-for-ct?per_page=30`
  let allDepartments = []
  let nextPageUrl = url

  return new Promise((resolve, reject) => {
    const loadData = async () => {
      let finalResponse = null
      try {
        // Loop until no next page is available
        while (nextPageUrl) {
          // Convert `http://` to `https://` if necessary
          if (nextPageUrl.startsWith('http://')) {
            nextPageUrl = nextPageUrl.replace('http://', 'https://')
          }

          // Fetch the current page
          const response = await axiosInstance.get(nextPageUrl, {
            params: queryParams
          })

          // Keep track of the latest response (for final structure)
          finalResponse = response

          // Accumulate the current page's departments
          const currentDepts = response?.data?.data?.departments || []
          allDepartments = allDepartments.concat(currentDepts)

          // Check for next page
          nextPageUrl = response?.data?.meta?.pagination?.links?.next || null
        }

        // Mutate the final response to include all accumulated departments
        if (finalResponse) {
          finalResponse.data = finalResponse.data || {}
          finalResponse.data.data = finalResponse.data.data || {}
          finalResponse.data.data.departments = allDepartments
        }

        // Commit the final response to setDepartmentOpts (assuming it expects this structure)
        if (finalResponse) {
          context.commit('setDepartmentOpts', finalResponse, { root: false })
        }

        // Resolve with the final data
        resolve(finalResponse)
      } catch (error) {
        console.error('Error loading department opts:', error)
        reject(error)
      } finally {
        // End loading state
        context.commit('auth/setDepartmentOptsLoading', false, { root: true })
      }
    }

    // Start fetching
    loadData()
  })
}
