import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import isNil from 'lodash/isNil'

function loadBookingsGeneric(context, payload = {}) {
  payload.queryParams = !isNil(payload.queryParams) ? payload.queryParams : {}

  let url = API.BOOKINGS

  payload.queryParams['filter[type]'] = 'text_translation'

  // Handle different booking categories
  if (payload.bookingCategory === 'historic') {
    url += '/historic'
    payload.queryParams['filter[status_id]'] = [4, 5, 6, 7, 8, 9, 10, 12, 13].join(',')
  } else if (payload.bookingCategory === 'potential') {
    url += '/potential'
  } else {
    payload.queryParams['filter[status_id]'] = [1, 2, 3, 11].join(',')
  }

  // Initialize pagination
  let allBookings = [] // Accumulate bookings from all pages
  let nextPageUrl = url // Start with the initial URL

  return new Promise((resolve, reject) => {
    const loadData = async () => {
      try {
        // Loop through paginated data until no next page is found
        while (nextPageUrl) {
          // Ensure the URL uses HTTPS
          if (nextPageUrl.startsWith('http://')) {
            nextPageUrl = nextPageUrl.replace('http://', 'https://')
          }

          // Make the API request
          const response = await axiosInstance.get(nextPageUrl, {
            params: payload.queryParams
          })

          // Collect the bookings from the current page
          const currentData = response?.data?.data?.bookings || []
          allBookings = allBookings.concat(currentData)

          // Get the next page URL from the API response
          nextPageUrl = response?.data?.meta?.pagination?.links?.next || null
        }

        // Format the result in the expected structure
        const result = {
          data: {
            data: {
              bookings: allBookings
            }
          }
        }

        // Resolve the promise with the formatted result
        resolve(result)
      } catch (error) {
        // Handle any errors
        console.error('Error loading bookings:', error)
        reject(error)
      }
    }

    // Call the async function
    loadData()
  })
}

export default loadBookingsGeneric
