import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import isNil from 'lodash/isNil'

function loadBookingsGeneric(context, payload = {}) {
  payload.queryParams = !isNil(payload.queryParams) ? payload.queryParams : {}

  let url = API.BOOKINGS
  const method = 'GET'

  payload.queryParams['filter[type]'] = [
    'physical',
    'phone',
    'video',
    'video_physical',
    'video_skype',
    'convey',
    'video_emergency'
  ].join(',')

  // Modify the URL based on the booking category
  if (payload.bookingCategory === 'historic') {
    url += '/historic'
    payload.queryParams['filter[status_id]'] = [4, 5, 6, 7, 8, 9, 10, 12, 13].join(',')
  } else if (payload.bookingCategory === 'potential') {
    url += '/potential'
  } else {
    payload.queryParams['filter[status_id]'] = [1, 2, 3, 11].join(',')
  }

  // Initialize variables for pagination
  let allBookings = []
  let nextPageUrl = url // Initial URL for the first request

  return new Promise((resolve, reject) => {
    const loadData = async () => {
      try {
        // Loop to fetch all pages until no next page is available
        while (nextPageUrl) {
          // Ensure the URL uses HTTPS
          if (nextPageUrl.startsWith('http://')) {
            nextPageUrl = nextPageUrl.replace('http://', 'https://')
          }

          const response = await axiosInstance.request({
            url: nextPageUrl,
            method,
            params: payload.queryParams
          })

          // Collect the bookings from the current page
          const currentData = response?.data?.data?.bookings || []
          allBookings = allBookings.concat(currentData)

          // Get the next page URL from the API response
          nextPageUrl = response?.data?.meta?.pagination?.links?.next || null
        }

        // Resolve the promise to indicate success
        resolve(formatResult(allBookings))
      } catch (error) {
        console.error('Error loading bookings:', error)
        reject(error)
      }
    }
    loadData()
  })
}

function formatResult(result) {
  return {
    data: {
      data: {
        bookings: result
      }
    }
  }
}

export default loadBookingsGeneric
