/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > readAuthUserMeta
|--------------------------------------------------------------------------
|
*/
import * as Sentry from '@sentry/browser'
import { reach } from '@/modules/helpers/vuexActions'
import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import assignIn from 'lodash/assignIn'
import isNil from 'lodash/isNil'
import store from '@/store'
import router from '@/router'
import BOOKING_TYPE from '@/modules/constants/bookingType'
import USER_TYPE from '@/modules/constants/userType'

/**
 * Reach out to API to get data for the authenticated user.
 *
 * @param {Object} context - Current context of Vuex module.
 * @param {Object} [payload] - Contains necessary values.
 * @returns {*}
 */
export default function (context, payload = {}) {
  let includes = ['tours']

  const isUserCustomer = context.getters['isUserCustomer']
  const isUserTranslator = context.getters['isUserTranslator']
  const isUserAdmin = context.getters['isUserAdmin']

  if (isUserCustomer) {
    includes.push('customer_data.department.company.municipality')
    includes.push('customer_notification_types')
    includes.push('booking_types')
    includes.push('blacklisted_users.translator')
    includes.push('user_notification_preference.template')
    includes.push('booking_field')
  } else if (isUserTranslator) {
    includes.push('translator_data.video_emergency_availability')
    includes.push('translator_notification_setting')
    includes.push('languages.level')
    includes.push('countries')
    // includes.push('dialects')
    includes.push('user_notification_preference.template')
    includes.push('black_listed_municipalities')
    includes.push('black_listed_municipalities')
    includes.push('translator_customer_types')
    includes.push('training_records.training')
    includes.push('language_pair_levels')
    includes.push('booking_types')
  } else if (isUserAdmin) {
    includes.push('booking_types')
  }

  const method = 'GET'
  const url = API.AUTH_USER
  let options = {
    query_params: {
      include: includes.join(','),
      append: 'instant_translations_count'
    },

    on_success(r, ctx) {
      if (!r.data.data.user) {
        Sentry.setContext('userMeta', r.data.data)
        throw new Error(`User not defined in response to ${url}`)
      }

      if (
        r?.data?.data?.user?.is_purchase_manager &&
        router.currentRoute.value.path !== '/customer/statistics' &&
        router.currentRoute.value.path !== '/customer/bookings' &&
        router.currentRoute.value.path !== '/customer/bookings/historic' &&
        router.currentRoute.value.path !== '/text-translations' &&
        router.currentRoute.value.path !== '/text-translations/historic' &&
        router.currentRoute.value.path !== '/users'
      ) {
        router.push('/customer/statistics')
        // window.location = '/customer/statistics'
      }
      ctx.commit('auth/setUserMetaData', r.data.data, { root: true })
      ctx.commit('setUserNotificationPreference', r.data.data.user?.user_notification_preference)
      ctx.commit('setBlackListedMunicipalities', r.data.data.user?.black_listed_municipalities)
      ctx.commit('setTimezone', r.data.data.user?.timezone)
      store.commit('booking/setBcfConfig', r.data.data.user?.booking_field)
      if (r.data.data.user.type === USER_TYPE.CUSTOMER) {
        store.dispatch('textTranslation/getCustomerMarketings')
        if (r.data.data?.user?.customer_data?.customer_integration_enabled) {
          context.dispatch('customerIntegration/getToken', {}, { root: true })
        }
      }
      if (!r.data.data.user?.booking_types?.some((booking) => booking.booking_type === BOOKING_TYPE.TEXT_TRANSLATION)) {
        store.dispatch('textTranslation/validateTextTranslator', r.data.data.user.email)
      }
      if (r.data?.data?.user?.recruitment_candidate_id) {
        store.dispatch('textTranslation/getTextTranslationTestUrl', r.data?.data?.user?.recruitment_candidate_id)
      }
      if (isUserTranslator) {
        store.commit(
          'textTranslation/setManualSubmissionTillDate',
          r.data?.data?.user?.translator_data?.manual_submission_till_date
        )
        store.commit(
          'textTranslation/setManualSubmissionEnabled',
          r.data?.data?.user?.translator_data?.manual_submission_enabled
        )
      }
    },

    on_finally(ctx) {
      ctx.commit('auth/setUserMetaLoading', false, { root: true })
    }
  }

  if (!isNil(payload.query_params)) {
    assignIn(options.query_params, payload.query_params)
  }

  context.commit('auth/setUserMetaLoading', true, { root: true })
  return reach(this, context, url, method, options, axiosInstance)
}
