import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import appStorage from '@/modules/helpers/appStorage'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'

export default async function (context) {
  context.commit('auth/setTranslatorLevelOptsLoading', true, { root: true })

  let allResults = []
  let nextPageUrl = API.TRANSLATOR_LEVELS + '?sort=id' // Initial API endpoint

  try {
    // Keep fetching pages until there's no `next` link in the pagination
    while (nextPageUrl) {
      if (nextPageUrl.startsWith('http://')) {
        nextPageUrl = nextPageUrl.replace('http://', 'https://')
      }
      const response = await axiosInstance.request({
        url: nextPageUrl,
        method: 'GET'
      })

      // Concatenate the current page of translator levels to our results
      const currentData = response?.data?.data?.translator_levels || []
      allResults = allResults.concat(currentData)
      // Determine if there's a next page
      nextPageUrl = response?.data?.meta?.pagination?.links?.next || null
    }

    // Commit the accumulated results to Vuex
    const dataToStore = cloneDeep(allResults)
    context.commit('setTranslatorLevelOpts', formatResults(dataToStore))

    // Optionally save the data to local storage
    appStorage.set('dt_translator_levels', formatResults(dataToStore))
    appStorage.set('dt_translator_levels_updated_at', JSON.stringify(moment()))
  } catch (error) {
    console.error('Error loading translator levels:', error)
    // Optionally handle error state here
  } finally {
    // Reset loading state
    context.commit('auth/setTranslatorLevelOptsLoading', false, { root: true })
  }
}

function formatResults(translator_levels) {
  return {
    data: {
      data: {
        translator_levels
      }
    }
  }
}
