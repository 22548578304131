/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > getTowns
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

export default function () {
  const url = API.TOWNS + '?per_page=50'

  // Remove 'all' and build the queryParams
  let queryParams = {
    'fields[towns]': 'id,name',
    'sort': 'name'
  }

  return new Promise((resolve, reject) => {
    const loadData = async () => {
      let nextPageUrl = url
      let allTowns = []
      let finalResponse = null

      try {
        // Loop until there's no next page
        while (nextPageUrl) {
          // Ensure HTTPS if needed
          if (nextPageUrl.startsWith('http://')) {
            nextPageUrl = nextPageUrl.replace('http://', 'https://')
          }

          // Request the current page
          const response = await axiosInstance.get(nextPageUrl, {
            params: queryParams
          })

          // Keep track of the final response so we can mutate it later
          finalResponse = response

          // Accumulate the current page of towns
          const currentTowns = response?.data?.data?.towns || []
          allTowns = allTowns.concat(currentTowns)

          // Check if there's another page
          nextPageUrl = response?.data?.meta?.pagination?.links?.next || null
        }

        // Once all pages are fetched, mutate the final response to include all towns
        if (finalResponse) {
          finalResponse.data = finalResponse.data || {}
          finalResponse.data.data = finalResponse.data.data || {}
          finalResponse.data.data.towns = allTowns
        }

        // Resolve with the final mutated response, preserving the same structure
        resolve(finalResponse)
      } catch (error) {
        console.error('Error loading towns:', error)
        reject(error)
      }
    }

    // Initiate data load
    loadData()
  })
}
