export default {
  setUsers(state, users) {
    state.users = users
  },
  setPagination(state, pagination) {
    state.pagination = pagination
  },
  setLoading(state, loading) {
    state.loading = loading
  }
}
