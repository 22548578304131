/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > loadTranslatorOpts
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import isNil from 'lodash/isNil'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action to load translator options.
 *
 * @param {Object} context - Current context of the Vuex module.
 * @param {Object} payload - Contains necessary values usable within the method.
 * @param {Object} payload.translator_name - Filter for getting translators with specific name(s).
 * @param {Object} payload.translator_id - Filter for getting translators with specific id(s).
 * @returns {Promise}
 */
export default function (context, payload = {}) {
  const url = API.TRANSLATORS + '-all'

  // Start loading
  context.commit('auth/setTranslatorOptsLoading', true, { root: true })

  // If no translator_name or translator_id provided, skip loading all data
  if (
    (isNil(payload.translator_name) || payload.translator_name === '') &&
    (isNil(payload.translator_id) || payload.translator_id === '' || payload.translator_id === 0)
  ) {
    console.warn('prevent load all translator data.')
    // End loading and return a resolved promise with a default response
    context.commit('auth/setTranslatorOptsLoading', false, { root: true })
    return Promise.resolve(context.state.defaultResponse)
  }

  // Build query parameters (removed `all: true`)
  let queryParams = {
    'fields[users]': 'id,name',
    'sort': 'name',
    'filter[enable]': 1,
    'per_page': 50
  }

  // Apply filters if provided
  if (!isNil(payload.translator_name) && payload.translator_name !== '') {
    queryParams['filter[name]'] = payload.translator_name
  }
  if (!isNil(payload.translator_id) && payload.translator_id !== '' && payload.translator_id !== 0) {
    queryParams['filter[id]'] = payload.translator_id
  }

  // Prepare for pagination
  let nextPageUrl = url
  let allTranslators = []

  return new Promise((resolve, reject) => {
    const loadData = async () => {
      let finalResponse = null

      try {
        while (nextPageUrl) {
          // Ensure HTTPS if needed
          if (nextPageUrl.startsWith('http://')) {
            nextPageUrl = nextPageUrl.replace('http://', 'https://')
          }

          // Fetch current page
          const response = await axiosInstance.get(nextPageUrl, { params: queryParams })

          // Track this response for later mutation
          finalResponse = response

          // Accumulate the current page's translators
          const currentData = response?.data?.data?.translators || []
          allTranslators = allTranslators.concat(currentData)

          // Determine if there's another page
          nextPageUrl = response?.data?.meta?.pagination?.links?.next || null
        }

        // Mutate final response with all translators
        if (finalResponse) {
          finalResponse.data = finalResponse.data || {}
          finalResponse.data.data = finalResponse.data.data || {}
          finalResponse.data.data.translators = allTranslators
        }

        // Commit the final (accumulated) result
        if (finalResponse) {
          context.commit('setTranslatorOpts', finalResponse, { root: false })
        }

        // Resolve the promise with the final response
        resolve(finalResponse)
      } catch (error) {
        console.error('Error loading translator options:', error)
        reject(error)
      } finally {
        // End loading
        context.commit('auth/setTranslatorOptsLoading', false, { root: true })
      }
    }

    // Start the data loading
    loadData()
  })
}
