import appStorage from '@/modules/helpers/appStorage'
import { CUSTOMER_INTEGRATION_USER } from '@/modules/constants/localStorage'
import { CUSTOMER_INTEGRATION_API } from '@/modules/constants/api'
import apiCall from '@/modules/helpers/apiCall'

export default async function (context, payload = {}) {
  const existingCSUser = appStorage.get(CUSTOMER_INTEGRATION_USER)
  let allWebhooks = []
  let nextPageUrl = `${CUSTOMER_INTEGRATION_API}/api/user-webhooks`
  let params = { ...payload, page: 1, per_page: 50 } // Adjust per_page if needed

  while (nextPageUrl) {
    const response = await apiCall({
      isShowLogs: false,
      headers: {
        Authorization: `Bearer ${existingCSUser.cs_token}`
      },
      url: nextPageUrl,
      method: 'GET',
      params
    })

    if (response && response.data) {
      const { user_webhooks } = response.data.data
      const { pagination } = response.data.meta

      if (user_webhooks) {
        allWebhooks.push(...user_webhooks)
      }

      nextPageUrl = pagination?.links?.next || null // Get next page URL
      params.page += 1 // Increment page number for next request
    } else {
      nextPageUrl = null // Stop fetching if no more data
    }
  }

  return allWebhooks
}
