<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div>
    <div :class="getBem(blockClass)">
      <el-menu
        ref="rootMenu"
        :class="getBem(blockClass, 'menu')"
        :mode="mode"
        text-color="#333"
        :active-text-color="`var(--app-primary-color)`"
        background-color="#ffffff"
        :popper-class="getBem(blockClass, 'popper')"
        :ellipsis="false"
      >
        <!-- Customer/Translator Nav-->
        <template v-if="isUserAuthenticated && !isUserAdmin && !isGuestMode">
          <!-- Home Nav -->
          <template v-if="isUserAuthenticated && !isPurchasingManager">
            <el-menu-item
              index="today_dashboard"
              :class="getBem(blockClass, ['menu-item'])"
              :tabindex="0"
              @keyup.enter.native="handleEnterMenuItem"
            >
              <router-link
                :to="getLocaleRoutePath('/today-dashboard')"
                :class="getBem(blockClass, ['menu-item', 'dashboard'])"
                :tabindex="-1"
                @click.native="handleMobileMenu"
              >
                {{ $t('home') }}
              </router-link>
            </el-menu-item>
          </template>

          <!-- Booking Nav Items -->
          <template v-if="(!isUserAuthenticated || !isUserTranslator) && !isPurchasingManager">
            <el-menu-item
              index="booking"
              :class="getBem(blockClass, ['menu-item'])"
              :tabindex="0"
              @keyup.enter.native="handleEnterMenuItem"
            >
              <template #title>
                <router-link
                  :to="getLocaleRoutePath('/')"
                  :class="getBem(blockClass, ['menu-item', 'booking'])"
                  :tabindex="-1"
                  @click.native="handleMobileMenu"
                >
                  {{ $t('nav_booking') }}
                </router-link>
              </template>
            </el-menu-item>
          </template>

          <!-- Users menu -->
          <el-menu-item
            v-if="isPurchasingManager"
            index="users"
            :class="getBem(blockClass, ['menu-item'])"
            :tabindex="0"
            @keyup.enter.native="handleEnterMenuItem"
          >
            <template #title>
              <router-link
                :to="getLocaleRoutePath('/users')"
                :class="getBem(blockClass, ['menu-item', 'users'])"
                :tabindex="-1"
                @click.native="handleMobileMenu"
              >
                {{ $t('users') }}
              </router-link>
            </template>
          </el-menu-item>

          <!-- Potential Bookings -->
          <el-menu-item
            v-if="isUserAuthenticated && isUserTranslator"
            index="potential_bookings"
            :class="getBem(blockClass, ['menu-item'])"
            :tabindex="0"
            @keyup.enter.native="handleEnterMenuItem"
          >
            <template #title>
              <router-link
                :to="getLocaleRoutePath('/translator/bookings/potential')"
                :class="getBem(blockClass, ['menu-item', 'potential_bookings'])"
                :tabindex="-1"
                @click.native="handleMobileMenu"
              >
                {{ $t('nav_potential_bookings') }}
              </router-link>
            </template>
          </el-menu-item>

          <!-- Booking List -->
          <el-sub-menu
            v-if="isUserAuthenticated"
            index="my_bookings_submenu"
            :class="getBem(blockClass, ['submenu'])"
            :popper-class="getBem(blockClass, 'submenu-popper')"
            data-submenu="my_bookings"
            :tabindex="0"
            data-cy="nav-bookings-menu"
            @keyup.enter.native="handleEnterMenuItem"
          >
            <template #title>
              <router-link
                slot="title"
                :to="myBookingsLocalePath"
                :class="getBem(blockClass, ['submenu', 'my-bookings-submenu'])"
                :tabindex="-1"
              >
                {{ navMyBookingsLabel }}
              </router-link>
            </template>
            <!-- My Bookings -->
            <el-menu-item
              index="my_bookings"
              :class="getBem(blockClass, ['menu-item'])"
              data-parent="my_bookings"
              :tabindex="0"
              @keyup.enter.native="handleChildEnterNavItem"
              @keyup.tab.native="handleSubmenuTab"
            >
              <template #title>
                <router-link
                  :to="myBookingsLocalePath"
                  :class="getBem(blockClass, ['menu-item', 'my-bookings'])"
                  @click.native="handleMobileMenu"
                >
                  {{ $t('nav_sub_my_bookings') }}
                </router-link>
              </template>
            </el-menu-item>

            <!-- Historic Bookings -->
            <el-menu-item
              index="historic_bookings"
              :class="getBem(blockClass, ['menu-item'])"
              data-parent="my_bookings"
              :tabindex="0"
              @keyup.enter.native="handleChildEnterNavItem"
              @keyup.tab.native="handleSubmenuTab"
            >
              <template #title>
                <router-link
                  :to="historicBookingsLocalePath"
                  :class="getBem(blockClass, ['menu-item', 'historic-bookings'])"
                  data-cy="historic-bookings-link"
                  @click.native="handleMobileMenu"
                >
                  {{ $t('nav_historic_bookings') }}
                </router-link>
              </template>
            </el-menu-item>
          </el-sub-menu>

          <!-- Text translations nav items -->
          <el-sub-menu
            v-if="isUserAuthenticated"
            index="text-translations-submenu"
            :class="getBem(blockClass, ['submenu', 'text-translation-submenu'])"
            :popper-class="getBem(blockClass, 'submenu-popper')"
            data-submenu="text_translations"
            :tabindex="0"
            @keyup.enter.native="handleEnterMenuItem"
          >
            <template #title>
              <router-link
                slot="title"
                :to="
                  isUserCustomer ? getLocaleRoutePath('/text-translations') : getLocaleRoutePath('/text-translations')
                "
                :class="getBem(blockClass, ['menu-item', 'text-translations-booking'])"
                :tabindex="-1"
              >
                {{ navMyTextBookingsLabel }}
              </router-link>
              <span v-if="showTranslatorNotify" class="counter">{{ 1 }}</span>
            </template>

            <el-menu-item
              index="text-translations"
              :class="getBem(blockClass, ['menu-item'])"
              data-parent="text_translations"
              :tabindex="0"
              @keyup.enter.native="handleChildEnterNavItem"
              @keyup.tab.native="handleSubmenuTab"
            >
              <template #title>
                <router-link
                  :to="getLocaleRoutePath('/text-translations')"
                  :class="getBem(blockClass, ['menu-item', 'text-translations'])"
                  @click.native="handleMobileMenu"
                >
                  {{ navMyTextBookingsLabel }}
                </router-link>
              </template>
            </el-menu-item>
            <el-menu-item
              index="text-translations-historic"
              :class="getBem(blockClass, ['menu-item'])"
              data-parent="text_translations"
              :tabindex="0"
              @keyup.enter.native="handleChildEnterNavItem"
              @keyup.tab.native="handleSubmenuTab"
            >
              <template #title>
                <router-link
                  :to="getLocaleRoutePath('/text-translations/historic')"
                  :class="getBem(blockClass, ['menu-item', 'text-translations-historic'])"
                  @click.native="handleMobileMenu"
                >
                  {{ $t('nav_historic_text_translations') }}
                </router-link>
              </template>
            </el-menu-item>
          </el-sub-menu>

          <!-- Services Nav Item -->
          <el-menu-item
            v-if="!isUserAuthenticated"
            index="services"
            :class="getBem(blockClass, ['menu-item'])"
            data-parent="services"
            :tabindex="0"
            @keyup.enter.native="handleChildEnterNavItem"
            @keyup.tab.native="handleSubmenuTab"
          >
            <template #title>
              <router-link
                :to="getLocaleRoutePath('/services')"
                :class="getBem(blockClass, ['menu-item', 'services'])"
                @click.native="handleMobileMenu"
              >
                {{ $t('nav_services') }}
              </router-link>
            </template>
          </el-menu-item>

          <!-- About Us Nav Items -->
          <!-- <el-menu-item
          v-if="!isUserAuthenticated || isUserCustomer"
          index="about"
          :class="getBem(blockClass, ['menu-item'])"
          data-parent="about"
          :tabindex="0"
          @keyup.enter.native="handleChildEnterNavItem"
          @keyup.tab.native="handleSubmenuTab"
        >
          <template #title>
            <router-link
              :to="getLocaleRoutePath('/about/')"
              :class="getBem(blockClass, ['menu-item', 'about-digitaltolk'])"
              @click.native="handleMobileMenu"
            >
              {{ $t('nav_about') }}
            </router-link>
          </template>
        </el-menu-item> -->

          <!-- CARRERS -->
          <!-- <el-menu-item
          v-if="!isUserAuthenticated"
          index="careers"
          :class="getBem(blockClass, ['menu-item'])"
          :tabindex="0"
          @keyup.enter.native="handleEnterMenuItem"
        >
          <template #title>
            <router-link
              :to="getLocaleRoutePath('/about/careers')"
              :class="getBem(blockClass, ['menu-item', 'dashboard'])"
              :tabindex="-1"
              @click.native="handleMobileMenu"
            >
              {{ $t('nav_career') }}
            </router-link>
          </template>
        </el-menu-item> -->

          <!-- For Translator Nav Items -->
          <el-sub-menu
            v-if="!isUserCustomer"
            index="for_translator_submenu"
            :class="getBem(blockClass, ['submenu'])"
            :popper-class="getBem(blockClass, 'submenu-popper')"
            data-submenu="translator"
            :tabindex="0"
            @keyup.enter="handleEnterMenuItem"
          >
            <template #title>
              <router-link
                slot="title"
                :to="getLocaleRoutePath('/for-translator')"
                :class="getBem(blockClass, ['submenu', 'for-translator'])"
                :tabindex="-1"
              >
                {{ $t('nav_for_translator') }}
              </router-link>
            </template>
            <!-- For Translator -->
            <el-menu-item
              index="for_translator"
              :class="getBem(blockClass, ['menu-item'])"
              data-parent="translator"
              :tabindex="0"
              @keyup.enter.native="handleChildEnterNavItem"
              @keyup.tab.native="handleSubmenuTab"
            >
              <template #title>
                <router-link
                  :to="getLocaleRoutePath('/for-translator')"
                  :class="getBem(blockClass, ['menu-item', 'for-translator'])"
                  @click.native="handleMobileMenu"
                >
                  {{ $t('nav_for_translator') }}
                </router-link>
              </template>
            </el-menu-item>

            <!-- For Translator > Training Portal -->
            <el-menu-item
              index="training_portal"
              :class="getBem(blockClass, ['menu-item'])"
              data-parent="translator"
              :tabindex="0"
              @keyup.enter.native="handleChildEnterNavItem"
              @keyup.tab.native="handleSubmenuTab"
            >
              <!--          <a-->
              <!--            href="https://utbildning.digitaltolk.se/"-->
              <!--            target="_blank"-->
              <!--            rel=”noopener”-->
              <!--            :class="getBem(blockClass, ['menu-item', 'training-portal'])"-->
              <!--            @click="handleMobileMenu"-->
              <!--          >-->
              <!--            {{ $t('nav_training_portal') }}-->
              <!--          </a>-->
              <template #title>
                <router-link
                  :to="getLocaleRoutePath('/training-for-translator')"
                  :class="getBem(blockClass, ['menu-item', 'for-translator'])"
                  @click.native="handleMobileMenu"
                >
                  {{ $t('nav_training_portal') }}
                </router-link>
              </template>
            </el-menu-item>

            <!-- PriceLists Nav Item -->
            <el-menu-item
              v-if="isUserAuthenticated && !isUserEmployedTranslator"
              index="pricelists"
              :class="getBem(blockClass, ['menu-item'])"
              data-parent="translator"
              :tabindex="0"
              @keyup.enter.native="handleEnterMenuItem"
            >
              <template #title>
                <router-link
                  :to="getLocaleRoutePath('/price-lists')"
                  :class="getBem(blockClass, ['menu-item'])"
                  :tabindex="0"
                  @keyup.enter.native="handleChildEnterNavItem"
                >
                  {{ $t('nav_price_lists') }}
                </router-link>
              </template>
            </el-menu-item>
          </el-sub-menu>

          <el-menu-item
            v-if="isUserAuthenticated && isUserCustomer && !isPurchasingManager"
            index="course-menu"
            :class="getBem(blockClass, ['submenu'])"
            :popper-class="getBem(blockClass, 'submenu-popper')"
            data-submenu="course_menu"
            :tabindex="0"
            @keyup.enter.native="handleEnterMenuItem"
          >
            <!-- :to="{ path: '/courses', query: { type: 'press' } }" -->
            <template #title>
              <a :class="getBem(blockClass, ['submenu', 'press-course-submenu'])" :href="academyUrl" target="_blank">
                {{ $t('education') }}
              </a>
              <!--            </template>-->
              <!--            <el-menu-item-->
              <!--              v-for="course in courses"-->
              <!--              :key="course.id"-->
              <!--              index="courses"-->
              <!--              :class="getBem(blockClass, ['menu-item'])"-->
              <!--              :tabindex="0"-->
              <!--              @keyup.enter.native="handleEnterMenuItem"-->
              <!--            >-->
              <!--              <a :class="getBem(blockClass, ['menu-item', 'dashboard'])" @click="handleCourseSubmenuItem(course.id)">-->
              <!--                {{ $t(course.name) }}-->
              <!--              </a>-->
              <!--            </el-menu-item>-->
            </template>
          </el-menu-item>

          <!-- <el-sub-menu
          v-if="!isUserAuthenticated || !isUserTranslator || !isUserCustomer"
          index="blog-media-menu"
          :class="getBem(blockClass, ['submenu'])"
          :popper-class="getBem(blockClass, 'submenu-popper')"
          data-submenu="blog_media"
          :tabindex="0"
          @keyup.enter.native="handleEnterMenuItem"
        >
          <template #title>
            <router-link
              slot="title"
              :to="{ name: 'blogs', query: { type: 'press' } }"
              :class="getBem(blockClass, ['submenu', 'press-media-submenu'])"
              :tabindex="-1"
            >
              {{ $t('nav_blog') }}
            </router-link>
          </template>
          <el-menu-item
            index="blogs"
            :class="getBem(blockClass, ['menu-item'])"
            :tabindex="0"
            @keyup.enter.native="handleEnterMenuItem"
          >
            <router-link
              :to="{ name: 'blogs', query: { type: 'press' } }"
              :class="getBem(blockClass, ['menu-item', 'dashboard'])"
              :tabindex="-1"
              @click.native="handleMobileMenu"
            >
              {{ $t('nav_blog') }}
            </router-link>
          </el-menu-item>

          <el-menu-item
            index="blogs"
            :class="getBem(blockClass, ['menu-item'])"
            :tabindex="0"
            @keyup.enter.native="handleEnterMenuItem"
          >
            <router-link
              :to="{ name: 'blogs', query: { type: 'media' } }"
              :class="getBem(blockClass, ['menu-item', 'dashboard'])"
              :tabindex="-1"
              @click.native="handleMobileMenu"
            >
              {{ $t('media') }}
            </router-link>
          </el-menu-item>
        </el-sub-menu> -->

          <!-- Contact Nav Item -->
          <el-menu-item
            v-if="!isPurchasingManager"
            index="contact"
            :class="getBem(blockClass, ['menu-item'])"
            :tabindex="0"
            @keyup.enter.native="handleEnterMenuItem"
          >
            <router-link
              :to="getLocaleRoutePath('/contact')"
              :class="getBem(blockClass, ['menu-item', 'contact'])"
              :tabindex="-1"
              @click.native="handleMobileMenu"
            >
              {{ $t('nav_contact') }}
            </router-link>
          </el-menu-item>
        </template>
        <!-- Log in and Localization Nav-->
        <!-- Login Nav Item -->
        <template v-if="!$store.getters['auth/isGuestMode']">
          <template v-if="!$store.getters['auth/hasAuthenticatedUsers']">
            <el-menu-item
              index="login"
              :class="getBem(blockClass, ['menu-item'])"
              :tabindex="0"
              data-cy="login-menu-item"
              @keyup.enter.native="handleEnterMenuItem"
            >
              <router-link
                :to="getLocaleRoutePath('/login')"
                :class="getBem(blockClass, ['menu-item', 'login'])"
                :tabindex="-1"
                @click.native="handleMobileMenu"
              >
                <span>{{ $t('nav_login') }}</span>
              </router-link>
            </el-menu-item>
          </template>

          <template v-else>
            <!-- Email Nav Item -->
            <el-menu-item
              v-if="!isOnMobileViewport && !isUserAdmin"
              index="email"
              :class="getBem(blockClass, ['menu-item', 'email'])"
              tabindex="-1"
            >
              <template #title>
                <email-notification identifier="dynamic_nav" @openEmailMenu="handleEmailMenu" />
              </template>
            </el-menu-item>

            <!-- Messages Nav Item -->
            <el-menu-item
              v-if="!isOnMobileViewport && !isUserAdmin"
              index="messages"
              :class="getBem(blockClass, ['menu-item'])"
              tabindex="-1"
            >
              <router-link
                :to="getLocaleRoutePath('/email/inbox')"
                :class="getBem(blockClass, ['menu-item', 'message'])"
                style="padding: 0 5px"
              >
                <span class="el-dropdown-link">
                  <!--                <i class="el-icon-chat-dot-square"/>-->
                  <svg
                    class="el-icon-chat-dot-square"
                    width="18px"
                    height="18px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M23.7994 18.3704L23.8013 18.373C24.1307 18.8032 24.2888 20.2316 22.0258 19.9779C21.3596 19.9033 20.4282 19.7715 19.3088 19.3471C18.5551 19.0613 17.8986 18.7026 17.3584 18.3522C16.4699 18.7098 15.5118 18.9296 14.5113 18.9857C13.1436 20.8155 10.9602 22 8.50001 22C7.69152 22 6.91135 21.8717 6.17973 21.6339C5.74016 21.8891 5.24034 22.1376 4.68789 22.3471C3.56851 22.7715 2.63949 22.9297 1.97092 22.9779C1.47028 23.014 1.11823 22.9883 0.944098 22.9681C0.562441 22.9239 0.219524 22.7064 0.072134 22.3397C-0.0571899 22.0179 -0.0104055 21.6519 0.195537 21.3728C0.448192 21.0283 0.680439 20.6673 0.899972 20.3011C1.32809 19.5868 1.74792 18.8167 1.85418 17.9789C1.30848 16.9383 1.00001 15.7539 1.00001 14.5C1.00001 11.5058 2.75456 8.92147 5.29159 7.71896C6.30144 3.85296 9.81755 1 14 1C18.9706 1 23 5.02944 23 10C23 11.3736 22.6916 12.6778 22.1395 13.8448C21.9492 15.5687 22.8157 17.0204 23.7994 18.3704ZM7.00001 10C7.00001 6.13401 10.134 3 14 3C17.866 3 21 6.13401 21 10C21 11.1198 20.7378 12.1756 20.2723 13.1118C20.2242 13.2085 20.1921 13.3124 20.1772 13.4194C19.9584 14.9943 20.3278 16.43 21.0822 17.8083C19.9902 17.5451 18.9611 17.0631 18.0522 16.4035C17.7546 16.1875 17.3625 16.1523 17.0312 16.3117C16.1152 16.7525 15.0879 17 14 17C10.134 17 7.00001 13.866 7.00001 10ZM5.00353 10.2543C5.11889 14.4129 8.05529 17.8664 11.9674 18.7695C11.0213 19.5389 9.8145 20 8.50001 20C7.7707 20 7.07689 19.8586 6.44271 19.6026C6.14147 19.481 5.79993 19.5133 5.52684 19.6892C5.08797 19.972 4.56616 20.2543 3.9788 20.477C3.58892 20.6248 3.23263 20.7316 2.91446 20.8083C3.24678 20.2012 3.58332 19.4779 3.73844 18.7971C3.81503 18.461 3.8572 18.1339 3.87625 17.8266C3.88848 17.6293 3.84192 17.4327 3.74245 17.2618C3.27058 16.451 3.00001 15.5086 3.00001 14.5C3.00001 12.7904 3.78 11.263 5.00353 10.2543Z"
                      fill="#626272"
                    />
                  </svg>
                  <!--                <em v-if="emails.pagination.total > 0" class="counter">{{ getTotalUnreadMessagesCount}}</em>-->
                </span>
              </router-link>
            </el-menu-item>

            <!-- Auth Controls Nav Item -->
            <auth-controls-mobile v-if="isOnMobileViewport" />
            <template v-else>
              <el-sub-menu
                ref="authMenu"
                index="auth_controls"
                :class="getBem(authClass, ['submenu', 'auth-controls'])"
                :popper-class="getBem(authClass, 'submenu-popper')"
                tabindex="0"
                data-submenu="auth_controls"
                data-cy="auth-submenu"
              >
                <template #title>
                  <div :class="getBem(authClass, 'dropdown-name')" @click="handleTitleCLick">
                    {{ firstName }}
                  </div>
                  <div :class="getBem(authClass, 'dropdown-avatar')" @click="handleTitleCLick">
                    <!--suppress HtmlUnknownTarget -->
                    <profile-avatar :src="userAvatar" />
                  </div>
                </template>

                <!--Auth Profile Nav Item -->
                <el-menu-item
                  index="profile"
                  :class="getBem(authClass, ['menu-item'])"
                  :tabindex="0"
                  @click.native="handleEnterMenuItemAuth"
                >
                  <router-link
                    :to="getLocaleRoutePath('/profile')"
                    :class="getBem(authClass, ['menu-item', 'profile'])"
                    :tabindex="-1"
                    @click.native="handleMobileMenuAuth"
                  >
                    {{ $t('nav_profile') }}
                  </router-link>
                </el-menu-item>

                <el-menu-item
                  v-for="user in $store.getters['auth/authenticatedUsers']"
                  :key="user.id"
                  :class="[
                    getBem(authClass, 'authenticated-user'),
                    { unclickable: isUserIdCurrentlyLoggedIn(user.id) }
                  ]"
                  tabindex="0"
                  data-parent="auth_controls"
                  @click="handleClickSwitchUser(user.id)"
                  @keyup.enter.native="handleClickSwitchUser(user.id)"
                  @keyup.tab.native="handleTab()"
                >
                  <div :class="getBem(authClass, 'authenticated-user-avatar')">
                    <profile-avatar src="" />
                  </div>
                  <div :class="getBem(authClass, 'authenticated-user-details')">
                    <span>{{ user.name }}</span>
                    <span>{{ user.email }}</span>
                    <span>{{ getUserType(user.type) }}</span>
                  </div>
                  <div :class="getBem(authClass, 'authenticated-user-actions')">
                    <a
                      :class="getBem(authClass, 'authenticated-user-action-logout')"
                      href="#"
                      tabindex="0"
                      :data-cy="`logout-user-${user.email}`"
                      @click.prevent.stop="handleClickAuthenticatedUserLogout(user.id)"
                      @keyup.enter="handleClickAuthenticatedUserLogout(user.id)"
                    >
                      {{ $t('nav_logout') }}
                    </a>
                  </div>
                </el-menu-item>

                <!--Add Another User -->
                <el-menu-item
                  v-if="isShowAddAnotherUserAction"
                  :class="getBem(authClass, 'add-another-user')"
                  tabindex="0"
                  data-parent="auth_controls"
                  @click="handleClickAddAnotherUser"
                  @keyup.tab.native="handleSubmenuTabAuth"
                >
                  {{ $t('auth_add_another_user') }}
                </el-menu-item>

                <!--Logout All -->
                <el-menu-item
                  :class="getBem(authClass, 'logout-all')"
                  tabindex="0"
                  data-parent="auth_controls"
                  @click="handleClickLogoutAll"
                  @keyup.tab.native="handleSubmenuTabAuth"
                >
                  {{ $t('auth_logout_all') }}
                </el-menu-item>
              </el-sub-menu>
            </template>
          </template>
        </template>

        <template v-else>
          <el-menu-item
            index="login"
            :class="getBem(blockClass, ['menu-item'])"
            style="margin-right: 10px"
            :tabindex="0"
            data-cy="login-end-guest-session"
          >
            <span
              :class="getBem(blockClass, ['menu-item', 'login'])"
              :tabindex="-1"
              @click.native="handleClickEndGuestSession"
            >
              <span>{{ $t('end_guest_session') }}</span>
            </span>
          </el-menu-item>
        </template>

        <!-- Select Lang Nav Item -->
        <el-menu-item
          index="select_language"
          :class="getBem(blockClass, ['menu-item', 'select_language'])"
          :tabindex="0"
          @keyup.enter="handleEnterLangNavItem"
        >
          <template #title>
            <select-language :mode="isOnMobileViewport ? 'collapse' : 'dropdown'" @handleChange="handleChangeLocale" />
          </template>
        </el-menu-item>
        <!-- Admin Nav-->
        <template v-if="isUserAdmin">
          <el-menu-item
            v-if="isUserAuthenticated"
            index="admin_panel"
            :class="getBem(blockClass, ['menu-item'])"
            :tabindex="0"
            @keyup.enter.native="handleChildEnterNavItem"
          >
            <template #title>
              <router-link
                :to="getLocaleRoutePath('/admin-panel')"
                :class="getBem(blockClass, ['menu-item', 'admin-panel'])"
                @click.native="handleMobileMenu"
              >
                {{ $t('nav_admin_panel') }}
              </router-link>
            </template>
          </el-menu-item>
        </template>
      </el-menu>
    </div>
    <!-- Component DIV Wrapper -->
    <surveys-popover ref="survey-pop-over" @openSurveyModal="handleClickSuvey" />
    <show-survey-list-modal ref="show-survey-list-modal" />
  </div>
</template>

<script>
import AuthControlsMobile from '@/modules/auth/components/displays/AuthControlsMobile'
import SelectLanguage from '@/modules/auth/components/displays/SelectLanguage'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import EmailNotification from '@/modules/email/components/display/EmailNotification'
import EventBus from '@/modules/helpers/eventBus'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import ProfileAvatar from '@/modules/auth/components/displays/ProfileAvatar'

import SurveysPopover from '../../modules/email/components/display/SurveysPopover.vue'
import ShowSurveyListModal from '@/modules/communication/survey/modals/ShowSurveyListModal'

import i18nClient, { loadLocaleMessages } from '@/locales/i18nClient.js'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import API, { CUSTOMER_ACADEMY } from '@/modules/constants/api'
import BOOKING_TYPE from '@/modules/constants/bookingType'

export default {
  /*
  |--------------------------------------------------------------------------
  | Component > imported components
  |--------------------------------------------------------------------------
  */
  components: {
    ProfileAvatar,
    AuthControlsMobile,
    EmailNotification,
    SelectLanguage,

    SurveysPopover,
    ShowSurveyListModal
  },

  /*
  |--------------------------------------------------------------------------
  | Component > props
  |--------------------------------------------------------------------------
  */
  props: {
    mode: {
      type: String,
      default: 'horizontal'
    }
  },

  emits: ['openPickUserModal'],

  /*
  |--------------------------------------------------------------------------
  | Component > data
  |--------------------------------------------------------------------------
  */
  data() {
    return {
      authClass: 'auth-controls',
      blockClass: 'dynamic-nav',
      courses: []
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > computed
  |--------------------------------------------------------------------------
  */
  computed: {
    academyUrl() {
      return CUSTOMER_ACADEMY
    },
    navMyBookingsLabel() {
      return this.isPurchasingManager ? this.$t('bookings') : this.$t('nav_my_bookings')
    },
    navMyTextBookingsLabel() {
      return this.isPurchasingManager ? this.$t('nav_text_translations') : this.$t('nav_my_text_translations')
    },
    ...mapState('auth', ['language', 'user', 'popupShown']),

    ...mapGetters('auth', [
      'isShowMobileNav',
      'isOnMobileViewport',
      'isUserCustomer',
      'isUserAdmin',
      'isUserTranslator',
      'isUserEmployedTranslator',
      'getUserSurveys',
      'isPopupShown',
      'userMetaData',
      'isUserAuthenticated',
      'isGuestMode',
      'isPurchasingManager'
    ]),
    ...mapGetters({
      emails: 'email/getEmails',
      unReadEmailCount: 'email/getUnReadEmailCount'
    }),

    ...mapGetters('textTranslation', ['textTranslationTestUrl', 'isValidTextTranslator']),

    /**
     * @returns {boolean}
     */
    isUserAuthenticated() {
      return this.$store.getters['auth/isUserAuthenticated']
    },

    /**
     * @returns {boolean}
     */
    isUserCustomer() {
      return this.$store.getters['auth/isUserCustomer']
    },

    /**
     * @returns {boolean}
     */
    isUserTranslator() {
      return this.$store.getters['auth/isUserTranslator']
    },

    /**
     * Returns the correct local path for My Bookings according to user role.
     *
     * @return {String}
     */
    myBookingsLocalePath() {
      return this.isUserCustomer
        ? this.getLocaleRoutePath('/customer/bookings')
        : this.getLocaleRoutePath('/translator/bookings')
    },

    /**
     * Returns the correct local path for Historic Bookings according to user role.
     *
     * @return {String}
     */
    historicBookingsLocalePath() {
      return this.isUserCustomer
        ? this.getLocaleRoutePath('/customer/bookings/historic')
        : this.getLocaleRoutePath('/translator/bookings/historic')
    },

    iLanguage: {
      get() {
        return this.language
      },
      set(v) {
        this.saveLanguage(v)
      }
    },
    getTotalUnreadMessagesCount() {
      // return this.emails.pagination.total > 99 ? '99+' : this.emails.pagination.total
      return this.unReadEmailCount
    },
    /**
     * @returns {object|null}
     */
    storedUser() {
      return this.$store.getters['auth/user']
    },

    /**
     * @returns {boolean}
     */
    isStoredUserValid() {
      return !isNil(this.storedUser) && !isEmpty(this.storedUser)
    },

    /**
     * @returns {string}
     */
    firstName() {
      return this.isStoredUserValid && isString(this.storedUser.name)
        ? this.storedUser.name.split(' ')[0]
        : this.$t('login_as_placeholder')
    },

    /**
     * @returns {string}
     */
    userAvatar() {
      if (!this.isStoredUserValid) {
        return ''
      } else {
        return '' // Put avatar logic here
      }
    },

    /**
     * @returns {boolean}
     */
    isShowAddAnotherUserAction() {
      return this.$route.path !== '/login'
    },
    showTranslatorNotify() {
      const userMeta = this.userMetaData
      return (
        !this.$route?.name?.includes('text-translations') &&
        this.isUserTranslator &&
        !userMeta?.booking_types?.some((booking) => booking.booking_type === BOOKING_TYPE.TEXT_TRANSLATION) &&
        ((!userMeta?.recruitment_candidate_id && !this.isValidTextTranslator) ||
          (userMeta?.recruitment_candidate_id && this.textTranslationTestUrl))
      )
    }
  },

  watch: {
    'user'(val, pre) {
      if (val.id != pre.id) {
        this.setPopupShown(false)
      }
    },
    'isShowMobileNav'(val) {
      const el = document.querySelector('html')
      if (val) {
        el.classList.add('noscroll')
      } else {
        el.classList.remove('noscroll')
      }
    },
    'isUserCustomer': {
      handler(val) {
        if (val) {
          this.getCourses()
        }
      },
      immediate: true
    },
    '$route.path': {
      deep: true,
      handler() {
        this.loadMessagesNotifications()
        this.checkSurveys()
      }
    }
  },

  mounted() {
    this.loadMessagesNotifications()
    // this.checkSurveys()
  },

  /*
  |--------------------------------------------------------------------------
  | Component > methods
  |--------------------------------------------------------------------------
  */

  methods: {
    ...mapMutations('auth', ['saveLanguage', 'setIsShowMobilenav', 'setPopupShown']),
    ...mapActions('auth', ['getSurveys']),
    ...mapActions('blog', ['getBlogs', 'getFeaturedBlogs', 'searchBlogs']),
    ...mapActions('email', ['fetchEmails']),

    /**
     * Handler when 'openEmailMenu' event is triggered
     *
     * @returns {void}
     */
    handleEmailMenu() {
      console.log('Email from the PARENT!!!')
      setTimeout(() => {
        this.$refs.rootMenu.open('email')
      }, 250)
    },

    /**
     * Handler when 'openAuthMenu' event is triggered
     *_form
     * @returns {void}
     */

    handleCourseSubmenuItem(id) {
      this.$emit('openPickUserModal', id)
      this.setIsShowMobilenav(false)
    },
    handleTab() {
      console.log('Auth from the PARENT!!!')
      setTimeout(() => {
        this.$refs.rootMenu.open('auth_controls')
      }, 250)
    },

    /**
     * Handler when a nav item was clicked.
     *
     * @returns {void}
     */
    handleEnterMenuItem(event) {
      // go to the child "a" link
      const el = event.target
      el.querySelector('a').click()
    },

    handleMobileMenu() {
      // make sure to close the mobile menu (if on mobile)
      if (this.isOnMobileViewport) {
        this.$store.commit('auth/setIsShowMobilenav', false, { root: true })
      }
    },

    handleSubmenuTab(event) {
      const parentRef = event.target.closest('.el-menu-item').dataset.parent
      document.querySelector(`[data-submenu=${parentRef}] ~ li`).focus()

      // console.log(`Parent Menu is: `, parentRef)
    },

    /**
     * Handler when a enter is pressed on a nav item.
     *
     * @returns {void}
     */
    handleChildEnterNavItem(event) {
      // restore focus to the parent menu
      this.handleSubmenuTab(event)

      // don't proceed if prohibited link
      const prohibited = [
        'dynamic-nav__menu-item__select_language',
        'dynamic-nav__menu-item__auth-controls',
        'dynamic-nav__menu-item__email',
        'auth-controls__dropdown-button'
      ]
      const isProhibited = prohibited.some((condition) => event.target.classList.contains(condition))
      if (isProhibited) {
        console.log('Prohibited Link!')
        return
      }

      console.log('Event:', event)

      let href

      // determine actual "href" element
      if (event.target.childNodes[0].nodeName == 'A') {
        console.log('Its an A')
        href = event.target.childNodes[0].href
      } else {
        console.log('Its NOT an A')
        href = event.target.childNodes[0].childNodes[0].href
      }

      // get pathname from the URL
      var pathname = new URL(href).pathname

      // direct $route to go to that path
      this.$router.push({ path: pathname })
    },

    /**
     * Handler when a enter is pressed on a lang nav item.
     *
     * @returns {void}
     */
    handleEnterLangNavItem(event) {
      event.target.childNodes[0].childNodes[0].click()
    },

    /**
     * @return {void}
     */
    handleChangeLocale(locale) {
      const isVerbiageLoaded = !!localStorage.getItem(`app_verbiages_terms_${locale}`)

      // if verbiage profile isn't already loaded, refresh the page and it'll get loaded
      if (!isVerbiageLoaded) {
        loadLocaleMessages(i18nClient, locale, [locale])
      } else {
        // otherwise load the verbiage profile from localstorage without refreshing the page
        const verbiage = localStorage.getItem(`app_verbiages_terms_${locale}`)
        this.$i18n.setLocaleMessage(locale, JSON.parse(verbiage))
        this.$i18n.locale = locale
      }
      EventBus.emit('language-changed')
    },

    triggerEvent(el, type) {
      if ('createEvent' in document) {
        // modern browsers, IE9+
        let e = document.createEvent('HTMLEvents')
        e.initEvent(type, false, true)
        el.dispatchEvent(e)
        console.log('Event Triggerd: ', type)
      } else {
        // IE 8
        let e = document.createEventObject()
        e.eventType = type
        el.fireEvent('on' + e.eventType, e)
      }
    },

    // Handler to Load Unread Messages Notification
    async loadMessagesNotifications() {
      // await this.fetchEmails({ dt_user_id: this.user.id, unread: true })
      // await this.fetchEmails({ dt_user_id: this.user.id })
    },
    /**
     * @param {int} userId
     * @returns {boolean}
     */
    isUserIdCurrentlyLoggedIn(userId) {
      return this.isStoredUserValid && this.storedUser.id === userId
    },

    /**
     * @param {int} type
     * @returns {string}
     */
    getUserType(type) {
      if (type === 2) return this.$t('customer')
      else if (type === 3) return this.$t('translator')
    },

    /**
     * @param {int} userId
     * @returns {void}
     */
    handleClickSwitchUser(userId) {
      if (!this.isUserIdCurrentlyLoggedIn(userId)) {
        this.$store.dispatch('auth/switchAuthUser', userId, { root: true })
      } else {
        this.$router.push('/profile')
      }
    },

    // handleTab() {
    //   console.log('Menu should remain open!')
    //   this.$emit('openAuthMenu')
    // },

    handleSubmenuTabAuth(event) {
      const parentRef = event.target.closest('.el-menu-item').dataset.parent
      document.querySelector(`[data-submenu=${parentRef}] ~ li`).focus()

      console.log(`Parent Menu is: `, parentRef)
    },

    /**
     * @param {int} userId
     * @returns {void}
     */
    handleClickAuthenticatedUserLogout(userId) {
      // This is the correct position for this line.
      const hasUserBeenLoggedIn = this.isUserIdCurrentlyLoggedIn(userId)
      this.$store.dispatch('auth/removeFromAuthCollection', userId, {
        root: true
      })
      const isEmptyAuthenticatedUsers = isEmpty(this.$store.getters['auth/authenticatedUsers'])

      document.cookie = `app_token=; Domain=.digitaltolk.se; Path=/` // clear out site-wide auth token cookie

      if (hasUserBeenLoggedIn) {
        if (isEmptyAuthenticatedUsers) {
          this.$router.push({ path: '/login' })
        } else {
          this.$router.push({ path: '/login/switch-user' })
        }
      }
    },

    handleClickAddAnotherUser() {
      this.$router.push('/login')
    },

    handleClickEndGuestSession() {
      const path = this.getLocaleRoutePath('/logout-all', this)
      this.$router.push({ path })
    },

    handleClickLogoutAll() {
      this.$confirm(this.$t('auth_are_you_sure_logout_all_users'), {
        type: 'warning',
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no'),
        cancelButtonClass: 'app-button-default'
      })
        .then(() => {
          document.cookie = `app_token=; Domain=.digitaltolk.se; Path=/` // clear out site-wide auth token cookie

          const path = this.getLocaleRoutePath('/logout-all', this)
          this.$router.push({ path })
        })
        .catch((e) => {
          if (e != null) throw e
          console.log('Logout all users cancelled')
        })
    },

    /**
     * Handler when a nav item was clicked.
     *
     * @returns {void}
     */
    handleEnterMenuItemAuth() {
      // go to the child "a" link
      const path = this.getLocaleRoutePath('/profile', this)
      this.$router.push({ path })
    },

    handleMobileMenuAuth() {
      // make sure to close the mobile menu (if on mobile)
      if (this.isOnMobileViewport) {
        this.$store.commit('auth/setIsShowMobilenav', false, { root: true })
      }
    },

    handleTitleCLick() {
      if (this.$route.name !== 'profile') {
        this.$router.push({ path: '/profile' })
      }
    },
    async getCourses() {
      let res = await axiosInstance.get(API.COURSES)
      this.courses = res?.data?.data?.courses
    },

    checkSurveys() {
      if (this.isUserAuthenticated) {
        const emailPoppup = this.emails.pagination.total !== 0
        if ((this.$route.path == '/' && !this.popupShown) || (this.$route.path == '/today-dashboard' && !emailPoppup)) {
          this.getSurveys().then((res) => {
            var todayDate = new Date()
            res.data?.data?.user?.survey.forEach((element) => {
              var surveyEndDate = new Date(element.end_date)
              if (element.pivot.status != 'completed' && surveyEndDate > todayDate) {
                this.$refs['survey-pop-over'].open()
              }
            })
            if (this.$route.path == '/') {
              this.setPopupShown(true)
            }
          })
        }
      }
    },

    handleClickSuvey() {
      this.$refs['show-survey-list-modal'].open(this.getUserSurveys)
    }
  }
}
</script>

<!--suppress SassScssResolvedByNameOnly -->
<style lang="scss">
html.noscroll {
  position: relative;

  body {
    overflow: hidden;
  }
}

.dynamic-nav {
  text-align: right;

  &__menu {
    flex-wrap: wrap;
  }

  .el-menu--vertical {
    .el-menu-item {
      vertical-align: inherit;
    }

    .el-menu-item [class^='el-icon'] {
      color: #fff;
      font-size: 12px;
    }

    .el-sub-menu {
      &__title {
        .el-icon {
          color: #fff;
        }
      }
    }

    .dynamic-nav__menu-item__select_language {
      position: relative;
      width: 87%;

      & > div {
        width: 100%;
        position: absolute;
        top: 0;
      }
    }
  }

  .el-menu--horizontal {
    // display: inline-block;
    border-bottom: 0 !important;
    justify-content: end;
    align-items: center;

    .el-menu-item {
      padding-left: 0;
      padding-right: 0;
      height: 65px;

      & > a {
        padding: 0 10px;
      }
    }

    .el-sub-menu {
      &.dynamic-nav__submenu {
        &__text-translation-submenu {
          position: relative;

          .counter {
            display: inline-block;
            background: #e05353;
            color: #fff;
            font-size: 10px;
            font-weight: 600;
            font-style: normal;
            line-height: 1.2;
            text-align: center;
            padding: 1px 4px;
            border-radius: 100%;
            margin-left: 4px;
          }
        }
      }
    }

    .el-menu-item,
    .el-sub-menu__title {
      & > a {
        font-size: 13px;
        display: inline-block;
        color: $app-light-gray;
        transition-duration: 0.5s;
        letter-spacing: 0.05em;

        &.dynamic-nav__menu-item__admin-panel {
          font-size: 16px;
          font-weight: 600;
          color: #337ab7;
          text-decoration: underline;
          // margin-bottom: 5px;
        }

        &:hover {
          svg {
            path {
              fill: var(--app-primary-color);
            }
          }
        }
      }
    }

    .el-sub-menu {
      .el-sub-menu__title {
        padding-left: 12px;
        padding-right: 30px;
      }
    }

    .dynamic-nav__menu-item__select_language {
      & > div {
        display: flex;

        .el-select {
          .is-focus {
            box-shadow: unset !important;
          }

          .el-input {
            &__wrapper {
              box-shadow: unset !important;
              border: unset;
              // padding-left: 0;

              &:hover {
                box-shadow: unset;
              }
            }

            &__inner {
              &:focus {
                border-color: unset;
                box-shadow: unset;
              }
            }

            &__suffix {
              &-inner {
                .el-icon {
                  margin-right: 0;
                  margin-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .el-menu--horizontal &__menu-item__login {
    line-height: 30px;

    & > span {
      border: 1px solid $app-light-gray;
      border-radius: 25px;
      display: inline-block;
      padding: 0 10px;
      box-sizing: border-box;
      transition: border 0.5s;
    }
  }

  .el-menu--horizontal .dynamic-nav__menu-item:hover .dynamic-nav__menu-item__login {
    span {
      border-color: var(--app-primary-color);
    }
  }

  &__submenu-popper.el-menu--horizontal {
    & > .el-menu--popup {
      min-width: 150px;

      .el-menu-item {
        padding: 0;
      }

      .el-menu-item > a {
        padding: 10px 25px;
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
      }
    }
  }

  &__submenu-popper {
    background: unset !important;
    border: unset !important;
  }
}

.on-mobile-viewport .dynamic-nav {
  &__menu-item__auth-controls {
    height: auto;
    min-height: 56px;

    .el-collapse {
      position: relative;
      top: 2px;
    }

    .el-collapse-item__arrow {
      color: #909399;

      font-size: 12px;
      margin-right: 0;
      transform: rotate(90deg);
    }

    .el-collapse-item__arrow.is-active {
      transform: rotate(270deg);
    }
  }

  .el-sub-menu__icon-arrow .el-icon-arrow-down {
    color: #909399;
  }

  .language-menu {
    max-width: none;
  }

  .select_language {
    z-index: 99999;
  }
}

.el-dropdown-link {
  position: relative;
  padding: 0 5px;

  .el-icon-message-solid {
    font-size: 19px;
  }

  .counter {
    display: inline-block;
    position: absolute;
    left: 16px;
    top: -3px;
    background: #e05353;
    color: #fff;
    font-size: 10px;
    font-weight: 600;
    font-style: normal;
    line-height: 1.2;
    text-align: center;
    padding: 1px 4px;
    border-radius: 100%;
  }

  .dynamic-nav__menu-item__message {
    padding: 0 !important;
  }
}

// **************************************************
// Medium Viewport
// **************************************************
@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  .dynamic-nav {
    text-align: center;

    .el-menu--horizontal {
      display: inline-block;

      .el-menu-item,
      .el-sub-menu__title {
        padding: 0 5px;

        & > a {
          font-size: 13px;
        }
      }
    }

    .el-menu--horizontal &__menu-item__login {
      & > span {
        padding: 0 7px;
      }
    }
  }
}

.auth-controls {
  // Menu Link
  &__submenu__auth-controls {
    .el-sub-menu__title {
      display: flex;
      justify-content: center;
      align-items: center;

      .auth-controls {
        &__dropdown-avatar {
          margin: 0 5px;
        }

        &__dropdown-name {
          white-space: nowrap;
          width: 50px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  // Dropdown
  &__submenu-popper {
    top: 60px !important;
    border: unset !important;
    background: unset !important;

    .el-menu {
      .el-menu-item {
        &.auth-controls {
          // Menu Item - Auth User
          &__authenticated-user {
            display: inherit;

            .auth-controls {
              &__authenticated-user-avatar {
                .profile-avatar {
                  display: flex;
                }
              }

              &__authenticated-user-details {
                font-weight: 400;
                line-height: 1;
              }

              &__authenticated-user-actions {
                .auth-controls__authenticated-user-action-logout {
                  color: $app-secondary-color !important;

                  &:hover,
                  &:focus,
                  &:visited,
                  &:active {
                    color: $app-secondary-color !important;
                  }
                }
              }
            }
          }

          // Menu Item - Add User
          &__add-another-user,
          &__logout-all {
            padding: 5px 25px;
            justify-content: center;
          }
        }
      }
    }
  }
}

.auth-controls {
  &__dropdown {
    &-button {
      & > div {
        display: inline-block;
      }
    }

    &-icon {
      font-size: 12px !important;
    }

    &-name {
      font-size: 13px;
      position: relative;
    }

    &-avatar,
    &-avatar .profile-avatar,
    &-avatar .profile-avatar img {
      width: 24px;
      height: 24px;
    }

    &-avatar .profile-avatar {
      display: flex;
      border-radius: 50%;
      position: relative;
      overflow: hidden;

      & > img {
        position: absolute;
        left: 0;
      }
    }
  }

  &__menu-item {
    border-bottom: 1px solid #eaeaea;
    cursor: pointer;
    transition-duration: 0.5s;
  }

  &__authenticated-user {
    border-bottom: 1px solid #eaeaea;
    cursor: pointer;
    padding: 7px;
    transition-duration: 0.5s;

    //&.unclickable {
    //  cursor: default;
    //}

    &:last-child {
      padding-bottom: 7px;
      border-bottom: 0;
    }

    &-details,
    &-avatar {
      display: inline-block;
      vertical-align: middle;
    }

    &-avatar {
      margin-right: 12px;
    }

    &-avatar,
    &-avatar .profile-avatar,
    &-avatar .profile-avatar img {
      width: 24px;
      height: 24px;
    }

    &-avatar .profile-avatar {
      border-radius: 50%;
      overflow: hidden;
    }

    &-details {
      font-size: 12px;

      span {
        display: block;
        margin-bottom: 4px;
      }

      span:last-child {
        margin-bottom: 0;
      }
    }

    &-actions {
      text-align: right;
      padding-top: 3px;
      font-size: 12px;
    }

    &-action-logout {
      color: $app-secondary-color !important;

      &:hover,
      &:focus,
      &:visited,
      &:active {
        color: $app-secondary-color !important;
      }
    }

    &:hover:not(.unclickable) {
      background-color: var(--app-primary-color) !important;
      color: #fff;
    }
  }

  &__popper {
    width: auto;
    min-width: 200px;
    box-sizing: border-box;
    padding-top: 0;
    padding-bottom: 0;

    &-body {
      min-height: 100px;
      max-height: 70vh;
      overflow-y: auto;
    }

    &-footer {
      border-top: 1px solid #eaeaea;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &-footer > div {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__add-another-user,
  &__logout-all {
    text-align: center;
    font-size: 11px;
  }

  &__logout-all {
    a,
    a:hover,
    a:focus,
    a:visited,
    a:active {
      color: $app-secondary-color !important;
    }
  }

  &__collapse {
    border: 0;

    .el-collapse-item {
      border: 0;
      background: transparent;
      color: #fff;

      &__header,
      &__wrap,
      &__content {
        @extend .el-collapse-item;
      }

      &__content {
        padding-top: 10px;
        /*padding-left: 20px;*/
        padding-bottom: 15px;
      }
    }

    &-avatar,
    &-avatar .profile-avatar,
    &-avatar .profile-avatar img {
      width: 24px;
      height: 24px;
    }

    &-avatar .profile-avatar {
      border-radius: 50%;
      position: relative;
      overflow: hidden;

      & > img {
        position: absolute;
        left: 0;
      }
    }

    &-avatar {
      margin-right: 10px;
    }
  }

  padding-right: 10px;
}
</style>
