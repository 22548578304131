import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import axios from 'axios'

/**
 * Helpers for handling get file to AWS storage through dt storage endpoint
 *
 * @params {object} o
 * @params {url} o.url
 *
 * @return {FILE}
 */
export function download(payload) {
  let o = {
    params: `path=${payload.path}&display_name=${payload.display_name}`,
    filename: payload.display_name
  }

  let url = `${API.STORAGE}/get?${o.params}`

  return axiosInstance.get(url).then((r) => {
    // r.data.data.url;

    window.open(r.data.data.url, '_blank')
  })
}

/**
 * Helpers for handling store file to AWS storage through dt storage endpoint
 *
 *
 * @param {Object} va - The Vue App instance also known as "this".
 * @param file
 * @param options
 * @params {FILE} file
 * @params {options} options
 *
 * @return {object}
 */
export function storeFile(va, file, options = {}) {
  let url = API.SIGNED_STORAGE

  return new Promise((resolve, reject) => {
    axiosInstance
      .post(url, {
        bucket: options.bucket || '',
        content_type: options.contentType || file.type,
        expires: options.expires || ''
      })
      .then((response) => {
        if (typeof options.progress === 'undefined') {
          options.progress = () => {}
        }

        // delete Authorization if exist
        if ('Authorization' in axios.defaults.headers.common) {
          delete axios.defaults.headers.common.Authorization
        }

        axios
          .create()
          .put(response.data.url, file, {
            onUploadProgress: (progressEvent) => {
              options.progress(progressEvent.loaded / progressEvent.total)
            }
          })
          .then((r) => {
            if (response.data.extension) {
              response.data.extension = file.name.split('.').pop()
            }
            resolve(response)
            return r
          })
          .catch((e) => {
            reject(e)
          })
      })
      .catch((e) => {
        reject(e)
      })
  })
}

/**
 * Helpers for handling store file to AWS storage through dt storage endpoint
 *
 *
 * @param {Object} va - The Vue App instance also known as "this".
 * @param file
 * @param options
 * @params {FILE} file
 * @params {options} options
 *
 * @return {object}
 */
/* eslint-disable no-useless-catch */
export async function storeFileInChunks(va, file, config = {}) {
  try {
    const chunkSize = config?.chunk_size ?? 5 * 1024 * 1024 // minimum chunk size should be 5mib
    const options = {
      ...config,
      total_parts: Math.ceil(file.size / chunkSize)
    }

    const processMultipart = async () => {
      const multipartPreSignedResponse = await getMultipartPreSignedUrls(options)
      const preSignedUrls = multipartPreSignedResponse.data.urls
      const fileChunks = chunkFile(file, chunkSize)

      if (typeof options.progress === 'undefined') {
        options.progress = () => {}
      }

      options.progress(1 / fileChunks.length)

      const parts = []
      for (let i = 0; i < fileChunks.length; i++) {
        try {
          const part = await uploadChunk(preSignedUrls[i], fileChunks[i])
          parts.push({
            part_number: i + 1,
            e_tag: part
          })
          options.progress((i + 1) / fileChunks.length)
        } catch (error) {
          throw error
        }
      }
      try {
        await completeUpload({
          key: multipartPreSignedResponse.data.key,
          uploadId: multipartPreSignedResponse.data.uploadId,
          parts: parts
        })
        options.progress(1)
      } catch (error) {
        throw error
      }

      return {
        key: multipartPreSignedResponse.data.key,
        name: file.name,
        temp_url: multipartPreSignedResponse?.data?.temp_url
      }
    }
    return await processMultipart()
  } catch (error) {
    throw error
  }
}

/**
 * Get pre-signed urls for multipart upload
 * @param {*} options
 */
/* eslint-disable no-useless-catch */
async function getMultipartPreSignedUrls(options) {
  try {
    let url = API.SIGNED_STORAGE_MULTIPART

    return await axiosInstance.post(url, {
      bucket: options.bucket || '',
      content_type: options.content_type,
      expires: options.expires || '',
      total_parts: options.total_parts,
      with_temp_url: options?.with_temp_url || false
    })
  } catch (error) {
    console.error('error', error)
    throw error
  }
}

/**
 * Split file into chunks
 * @param {*} file
 * @param {*} chunkSize
 * @returns
 */
function chunkFile(file, chunkSize) {
  const chunks = []
  const fileSize = file.size
  let start = 0
  while (start < fileSize) {
    const end = Math.min(start + chunkSize, file.size)
    chunks.push(file.slice(start, end))
    start = end
  }
  return chunks
}

/**
 * Upload chunk to pre-signed url
 * @param {*} url
 * @param {*} chunk
 */
/* eslint-disable no-useless-catch */
async function uploadChunk(url, chunk, retries = 3) {
  for (let attempt = 0; attempt < retries; attempt++) {
    try {
      const response = await axios.create().put(url, chunk, {
        headers: {
          'Content-Type': 'application/octet-stream'
        }
      })
      const etag = response?.headers?.etag
      return etag.substring(1, etag.length - 1)
    } catch (error) {
      console.error('error', error)
      if (attempt === retries - 1) {
        throw error
      }
    }
  }
}

/**
 * Complete multipart upload
 * @param {*} payload
 * @returns
 */
/* eslint-disable no-useless-catch */
async function completeUpload(payload) {
  const url = API.SIGNED_STORAGE_MULTIPART_COMPLETE
  try {
    return await axiosInstance.post(url, payload)
  } catch (error) {
    console.error('Error:', error)
    throw error
  }
}

/**
 * Show File on new tab
 * @param payload
 * @returns {Promise<unknown>}
 */
export function showFile(payload) {
  let o = {
    params: `path=${payload.path}&display_name=${payload.display_name}`,
    filename: payload.display_name
  }

  let url = `${API.STORAGE}/get?${o.params}`

  return axiosInstance.get(url).then((r) => {
    window.open(r.data.data.url, '_blank')
  })
}

/**
 * Count words for given file
 * @param file
 * @returns {Promise<unknown>}
 */
export function countFileWords(data) {
  return new Promise((resolve, reject) => {
    const url = `${API.TEXT_TRANSLATION}/count-document-words`
    axiosInstance
      .post(url, data)
      .then((response) => {
        resolve(response.data)
        return response.data
      })
      .catch((error) => {
        console.error('error....', error)
        reject(error)
        return error
      })
  })
}

/**
 * Check file compability
 * @param file
 * @returns {Promise<unknown>}
 */
export function checkFileCompability(data) {
  return new Promise((resolve, reject) => {
    const url = `${API.TRANS_TARGET_URL}/api/v1/document-compatibility`

    axiosInstance
      .post(url, data)
      .then((response) => {
        resolve(response.data)
        return response.data
      })
      .catch((error) => {
        console.error('error....', error)
        reject(error)
        return error
      })
  })
}

/**
 * Get File Metadata
 * @param file
 * @returns {Promise<unknown>}
 */
export function getFileMetadata(data) {
  return new Promise((resolve, reject) => {
    const url = `${API.TEXT_TRANSLATION}/document-metadata`

    axiosInstance
      .post(url, data)
      .then((response) => {
        resolve(response.data)
        return response.data
      })
      .catch((error) => {
        console.error('error....', error)
        reject(error)
        return error
      })
  })
}

/**
 * Show File on new tab
 * @param payload
 * @returns {Promise<unknown>}
 */
export function showFileViaSignature(payload) {
  let o = {
    params: `signature=${payload.signature}&expires=${payload.expires}&path=${payload.path}&display_name=${payload.display_name}`,
    filename: payload.display_name
  }

  let url = `${API.STORAGE}/signed-get?${o.params}`

  return axiosInstance
    .get(url)
    .then((response) => {
      window.open(response.data.data.url, '_blank')
      return response
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}

/**
 * Get Images in file
 * @param file
 * @returns {Promise<unknown>}
 */
export async function getFileImages(fileUrl) {
  return new Promise((resolve, reject) => {
    const url = `${API.FLARE_API}/file/extract-images`

    const form = new FormData()
    form.append('signed', 'true')
    form.append('url', fileUrl)

    let headers = {
      'Content-Type': 'multipart/form-data'
    }

    axios({ url, method: 'POST', data: form, headers })
      .then((response) => {
        const data = response.data?.images?.map((image) => image?.s3_url)
        resolve(data)
        return data
      })
      .catch((error) => {
        console.error('error....', error)
        reject(error)
        return error
      })
  })
}

/**
 * Get text in file
 * @param file
 * @returns {Promise<unknown>}
 */
export async function getFileWordsCount(fileUrl) {
  return new Promise((resolve, reject) => {
    const url = `${API.FLARE_API}/file/process-everything`

    const form = new FormData()
    form.append('signed', 'true')
    form.append('url', fileUrl)

    let headers = {
      'Content-Type': 'multipart/form-data'
    }

    axios({ url, method: 'POST', data: form, headers })
      .then((response) => {
        resolve(response.data)
        return response.data
      })
      .catch((error) => {
        console.error('error....', error)
        reject(error)
        return error
      })
  })
}

/**
 * Get text in file
 * @param file
 * @returns {Promise<unknown>}
 */
export async function getImageFileText(fileUrl) {
  return new Promise((resolve, reject) => {
    const url = `${API.FLARE_API}/ocr/extract-text`

    const form = new FormData()
    form.append('signed', 'true')
    form.append('url', fileUrl)

    let headers = {
      'Content-Type': 'multipart/form-data'
    }

    axios({ url, method: 'POST', data: form, headers })
      .then((response) => {
        resolve(response.data)
        return response.data
      })
      .catch((error) => {
        console.error('error....', error)
        reject(error)
        return error
      })
  })
}
