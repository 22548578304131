/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > loadTranslatorPotentialBookings
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

export default function (context, payload = {}) {
  const url = `${API.BOOKINGS}/potential`
  const loading = 'setTranslatorPotentialBookingListLoading'

  // Commit to start loading
  context.commit(loading, true)

  // Build the `include` and `append` params
  let include = [
    'specific_translators',
    'video_booking',
    'booking_group.bookings',
    'booking_time_slots.translator.translator_unavailable_times'
  ]
  include = include.join(',')

  const append = ['acceptable_booking_dates']

  const fields = [
    'id,customer_id,due,duration,from_language_id,to_language_id,status_id,is_immediate,gender,' +
      'type,created_at,reference,booker_name,additional_field_label,additional_field,' +
      'additional_field_two_label,additional_field_two,staff_name,instructions,address,ended_at,' +
      'will_end_at,booking_group_id,parent_booking_id,staff_phone'
  ].join(',')

  // Combine default query params with any passed in from `payload`
  let query_params = {
    include,
    'paginator': payload.paginator, // if payload.paginator is set
    'fields[bookings]': fields,
    append,
    ...payload.query_params
  }

  // Prepare for pagination
  let nextPageUrl = url
  let allBookings = []

  return new Promise((resolve, reject) => {
    const fetchData = async () => {
      let finalResponse = null

      try {
        while (nextPageUrl) {
          // Ensure HTTPS if the URL starts with http://
          if (nextPageUrl.startsWith('http://')) {
            nextPageUrl = nextPageUrl.replace('http://', 'https://')
          }

          // Perform the GET request for the current page
          const response = await axiosInstance.get(nextPageUrl, {
            params: query_params
          })

          // Keep track of the final response object (we'll overwrite as we go)
          finalResponse = response

          // Collect the current page's bookings
          const currentBookings = response?.data?.data?.bookings || []
          allBookings = allBookings.concat(currentBookings)

          // Check if there's a next page
          nextPageUrl = response?.data?.meta?.pagination?.links?.next || null
        }

        // If we got at least one successful response, mutate it to contain all bookings
        if (finalResponse) {
          // Mutate the final response data to include *all* bookings combined
          finalResponse.data = finalResponse.data || {}
          finalResponse.data.data = finalResponse.data.data || {}
          finalResponse.data.data.bookings = allBookings
        }

        // Resolve with the final mutated response object
        resolve(finalResponse)
      } catch (error) {
        console.error('Error loading translator potential bookings:', error)
        reject(error)
      } finally {
        // This replaces `on_finally` from loadAll.
        context.commit(loading, false)
      }
    }

    fetchData()
  })
}
