/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > loadLanguageOpts
|--------------------------------------------------------------------------
|
*/

import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import appStorage from '../../../../helpers/appStorage'
import isNil from 'lodash/isNil'
import { COUNTRIES } from '@/modules/constants/countries'
import { getAppTenant } from '@/modules/helpers/multiTenancy'

function extractCountriesFromLangs(response) {
  let dialects = []
  const languagesArray = response?.data?.data?.languages || []

  for (let i = 0; i < languagesArray.length; i++) {
    const lang = languagesArray[i]
    if (lang.countries) {
      for (let j = 0; j < lang.countries.length; j++) {
        const countryIso = lang.countries[j]
        dialects.push({
          id: countryIso,
          name: COUNTRIES[countryIso],
          language_id: lang.id
        })
      }
    }
  }
  return dialects
}

export default function (context, payload = {}) {
  // If already loaded, skip fetching
  console.log('languageOpts', context.getters)
  if (context.getters['languageOpts'].length > 0) {
    return
  }

  if (context.getters['isLoadingLanguageOpts']) {
    return
  }

  // Retrieve cached data
  const tenantID = getAppTenant()?.uuid
  const base = import.meta.env.VITE_LANGUAGES_BASE || `https://languages-stg-api.digitaltolk.net`
  const url = `${base}/api/v3/languages-app/${tenantID}/languages`

  let languages = appStorage.get(context.state.dtLanguagesCookieKey)
  let updatedAt = appStorage.get(context.state.dtLanguagesUpdatedCookieKey)

  // Start loading
  context.commit('setLanguageOptsLoading', true)

  // Build query params (no 'all' needed, we use pagination)
  let queryParams = {
    'fields[languages]': 'id,name,iso_6391,iso_6393,call_us,is_non_vocal',
    'per_page': 100
  }

  // If user is not admin, only get active languages
  if (!context.getters['auth/isUserAdmin']) {
    queryParams['filter[active]'] = true
  }

  // If cache is missing, outdated, or forced reload, we fetch from API
  if (isNil(languages) || isNil(updatedAt) || payload.load) {
    console.log('payload languages', [languages, updatedAt, payload.load])
    let nextPageUrl = url
    let allLanguages = []
    let finalResponse = null

    return new Promise((resolve, reject) => {
      const loadData = async () => {
        try {
          // Loop until no next page
          while (nextPageUrl) {
            // Ensure HTTPS if needed
            if (nextPageUrl.startsWith('http://')) {
              nextPageUrl = nextPageUrl.replace('http://', 'https://')
            }

            const response = await axiosInstance.get(nextPageUrl, {
              params: queryParams
            })

            finalResponse = response

            // Accumulate the current page's languages
            const currentLangs = response?.data?.data?.languages?.data || []
            allLanguages = allLanguages.concat(currentLangs)

            // Check for the next page URL
            nextPageUrl = response?.data?.data?.languages?.next_page_url || null
          }

          // Mutate finalResponse to include all accumulated languages
          if (finalResponse) {
            // Overwrite the final response's array with our aggregated data
            finalResponse.data = finalResponse.data || {}
            finalResponse.data.data = finalResponse.data.data || {}
            finalResponse.data.data.languages = allLanguages

            // Commit to Vuex
            context.commit('setLanguageOpts', finalResponse)

            // Extract dialects for each language
            let dialects = extractCountriesFromLangs(finalResponse)
            context.commit('setCountryOpts', dialects)

            // Cache the final data
            appStorage.set(context.state.dtLanguagesCookieKey, finalResponse)
            appStorage.set(context.state.dtLanguagesUpdatedCookieKey, context.state.newLastUpdate)
          }

          resolve(finalResponse)
        } catch (error) {
          console.error('Error loading language options:', error)
          reject(error)
        } finally {
          // End loading
          context.commit('setLanguageOptsLoading', false)
        }
      }

      // Start data fetching
      loadData()
    })
  } else {
    console.log('languages', languages)
    console.log('countryOpts', extractCountriesFromLangs(languages))
    // Use cached data
    context.commit('setLanguageOpts', languages)
    context.commit('setCountryOpts', extractCountriesFromLangs(languages))
    context.commit('setLanguageOptsLoading', false)
  }
}
