/*
|--------------------------------------------------------------------------
| Store > Expense > Actions
|--------------------------------------------------------------------------
|
*/

import { loadAll, reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import isNil from 'lodash/isNil'
import { showSmallErrorNotif } from '@/modules/helpers/notifications'

export default {
  loadAllExpenseTypes(context) {
    const url = `/api/v3/financial/expense-types`
    const method = 'GET'

    // Initialize the paginated data collection
    let allExpenseTypes = []
    let nextPageUrl = url // Initial URL for the first request

    return new Promise((resolve, reject) => {
      const loadData = async () => {
        try {
          // Loop to fetch all pages until no next page is available
          while (nextPageUrl) {
            // Ensure the URL uses HTTPS
            if (nextPageUrl.startsWith('http://')) {
              nextPageUrl = nextPageUrl.replace('http://', 'https://')
            }

            const response = await axiosInstance.request({
              url: nextPageUrl,
              method
            })

            // Collect the expense types from the current page
            const currentData = response?.data?.data?.expense_types || []
            allExpenseTypes = allExpenseTypes.concat(currentData)

            // Check for the next page URL
            nextPageUrl = response?.data?.meta?.pagination?.links?.next || null
          }

          // Commit the accumulated data to the Vuex store
          context.commit('setExpenseTypes', { data: { data: { expense_types: allExpenseTypes } } })

          // Resolve the promise to indicate success
          resolve(allExpenseTypes)
        } catch (error) {
          // Handle any errors
          console.error('Error loading expense types:', error)
          reject(error)
        }
      }

      // Call the async function
      loadData()
    })
  },

  loadBookingExpenses(context, payload) {
    context.commit('setLoading', true)
    const url = `/api/v3/financial/bookings/${payload.id}/expenses`

    const method = 'GET'
    let options = {
      enable_error_notif: true,
      query_params: {
        per_page: 50,
        include: 'attachments,type'
      },
      on_finally(ctx) {
        ctx.commit('setLoading', false)
      }
    }

    return loadAll(this, context, url, method, 'setExpenseList', options, axiosInstance)
  },

  postExpense(context, payload) {
    context.commit('setLoading', true)

    let data = payload.data
    if (!isNil(data.attachments) && data.attachments.length > 0) {
      data.attachments_keys = data.attachments.map((i) => i.key)
    }
    let url = `/api/v3/financial/bookings/${payload.booking_id}/expenses`
    let method = 'POST'

    if (!isNil(payload.data.id)) {
      url = `/api/v3/financial/bookings/${payload.booking_id}/expenses/${payload.data.id}`
      method = 'PUT'
    }
    let options = {
      // enable_error_notif: true,
      data: data,
      on_success(r, ctx) {
        ctx.commit('resetForm')
      },
      on_error(e) {
        // console.log(e.request)
        console.log(e)
        if (e.request.status === 400) {
          showSmallErrorNotif('Cannot update expense', 'ERROR!!')
        }
      },
      on_finally(ctx) {
        ctx.commit('setLoading', false)
      }
    }

    return reach(this, context, url, method, options, axiosInstance)
  },

  deleteExpenseAttachement(context, payload) {
    let url = `/api/v3/financial/bookings/${payload.booking_id}/expenses/${payload.expense_id}/attachments/${payload.attachment_id}`
    let method = 'DELETE'
    let options = {}

    return reach(this, context, url, method, options, axiosInstance)
  }
}
