export default {
  loading(state) {
    return state.loading
  },
  users(state) {
    return state.users
  },
  pagination(state) {
    return state.pagination
  }
}
