import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import { CUSTOMERS } from '@/modules/constants/api'

export default function (context, payload) {
  const url = `${CUSTOMERS}/${payload.id}`
  const method = 'PUT'
  const data = {
    enabled: 0
  }

  context.commit('setLoading', true)
  return axiosInstance({
    url,
    method,
    data
  })
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error
    })
    .finally(() => {
      context.commit('setLoading', false)
    })
}
