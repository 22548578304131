/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > Sectors
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'

/**
 * Action to fetch all paginated sectors.
 *
 * @param {Object} context - Current context of Vuex module.
 * @param {Object} payload - Contains necessary values.
 * @returns {Promise<Array>} - Returns all fetched sectors.
 */
export default async function (context, payload) {
  const method = 'GET'
  let options = {
    query_params: {
      is_enabled: payload.is_enabled,
      per_page: 20, // Adjust per page if needed
      page: 1
    },
    headers: {
      'X-Recruitment-Client': 1
    }
  }

  let allSectors = []
  let nextPageUrl = API.SECTORS

  while (nextPageUrl) {
    const response = await reach(this, context, nextPageUrl, method, options)

    if (response && response.data) {
      const { client_sectors } = response.data.data
      const { pagination } = response.data.meta

      if (client_sectors) {
        allSectors.push(...client_sectors)
      }

      nextPageUrl = pagination?.links?.next || null // Get next page URL
      options.query_params.page += 1 // Increment page number for next request (if needed)
    } else {
      nextPageUrl = null // Stop fetching if no more data
    }
  }

  return allSectors
}
