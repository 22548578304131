import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import { getAppTenant } from '@/modules/helpers/multiTenancy'
import appStorage from '../../../../helpers/appStorage'
import moment from 'moment'

/**
 * Action for block a specific translator.
 *
 * @param {Object} context - Current context of Vuex module.
 * @param {Object} payload - Contains necessary values.
 * @param {Object} payload.user_id - Authenticated User ID.
 * @returns {*}
 */
export default function (context) {
  const tenantID = getAppTenant()?.uuid
  const method = 'GET'
  const base = import.meta.env.VITE_LANGUAGES_BASE || `https://languages-stg-api.digitaltolk.net`
  const url = base + `/api/v3/languages-app/${tenantID}/language/last-update`

  console.log('Calling this url (1)...', url)

  let options = {
    on_success(r, ctx) {
      if (r) {
        const newUpdate = r.data[0].updated_at
        const oldUpdate = appStorage.get(context.state.dtLanguagesUpdatedCookieKey)

        if (oldUpdate != null) {
          if (moment(newUpdate).isAfter(moment(oldUpdate))) {
            ctx.commit('setNewLastUpdate', r.data)
            ctx.dispatch('loadLanguageOpts', { load: true })
          }
        } else {
          ctx.commit('setNewLastUpdate', r.data)
          ctx.dispatch('loadLanguageOpts')
        }
      }
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
