import { generateLocaleRoute } from '@/modules/helpers/routes'
// import { getAppTenantConfig } from '@/modules/helpers/multiTenancy'
import authenticated from './middlewares/authenticated'
import customer from './middlewares/customer'
import translator from './middlewares/translator'
import notTranslator from './middlewares/notTranslator'
import setUiUrl from './middlewares/setUiUrl'
import notPurchaseManager from './middlewares/notPurchaseManager'
import map from 'lodash/map'

const isMaintenanceMode = import.meta.env.VITE_MAINTENANCE_ENABLED

const pathPatterns = [
  {
    path: '/maintenance-mode',
    component: () => import('../components/pages/maintenance'),
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/today-dashboard',
    name: 'today-dashboard',
    component: () => import('../components/pages/today-dashboard'),
    meta: {
      middleware: [authenticated, setUiUrl, notPurchaseManager]
    }
  },

  // --------------------------------------------------
  // TEXT TRANSLATION
  // --------------------------------------------------
  {
    path: '/text-translations',
    name: 'text-translations',
    component: () => import('../components/pages/text-translations/index'),
    meta: {
      middleware: [authenticated, setUiUrl]
    }
  },

  {
    path: '/text-translations/create',
    name: 'text-translations.create',
    component: () => import('../components/pages/text-translations/create'),
    meta: {
      middleware: [customer, setUiUrl, notPurchaseManager]
    }
  },

  {
    path: '/text-translations/historic',
    name: 'text-translations.historic',
    component: () => import('../components/pages/text-translations/historic'),
    meta: {
      middleware: [authenticated, setUiUrl]
    }
  },

  {
    path: '/text-translations/:id/submit',
    name: 'text-translations.submit',
    component: () => import('../components/pages/text-translations/_id/submit'),
    meta: {
      middleware: [translator, setUiUrl, notPurchaseManager]
    }
  },

  {
    path: '/users',
    name: 'users.index',
    component: () => import('../components/pages/users/index'),
    meta: {
      middleware: [customer, setUiUrl]
    }
  },

  // --------------------------------------------------
  // CUSTOMER
  // --------------------------------------------------
  {
    path: '/customer/bookings',
    name: 'customer.bookings',
    component: () => import('../components/pages/customer/bookings/index'),
    meta: {
      middleware: [customer, setUiUrl]
    }
  },

  {
    path: '/customer/bookings/historic',
    name: 'customer.bookings.historic',
    component: () => import('../components/pages/customer/bookings/my-historic'),
    meta: {
      middleware: [customer, setUiUrl]
    }
  },

  {
    path: '/customer/statistics',
    name: 'customer.bookings.statistics',
    component: () => import('../components/pages/customer/statistics'),
    meta: {
      middleware: [customer, setUiUrl]
    }
  },

  // --------------------------------------------------
  // TRANSLATOR
  // --------------------------------------------------
  {
    path: '/translator/bookings',
    name: 'translator.bookings',
    component: () => import('../components/pages/translator/bookings/all-index'),
    meta: {
      middleware: [translator, setUiUrl, notPurchaseManager]
    }
  },

  {
    path: '/translator/bookings/historic',
    name: 'translator.bookings.historic',
    component: () => import('../components/pages/translator/bookings/all-historic'),
    meta: {
      middleware: [translator, setUiUrl, notPurchaseManager]
    }
  },

  {
    path: '/translator/bookings/potential',
    name: 'translator.bookings.potential',
    component: () => import('../components/pages/translator/bookings/potential'),
    meta: {
      middleware: [translator, setUiUrl, notPurchaseManager]
    }
  },

  {
    path: '/translator/statistics',
    name: 'translator.statistics',
    component: () => import('../components/pages/translator/all-statistics'),
    meta: {
      middleware: [translator, setUiUrl, notPurchaseManager]
    }
  },

  {
    path: '/translator/earnings',
    name: 'translator.earnings',
    component: () => import('../components/pages/translator/earnings'),
    meta: {
      middleware: [translator, setUiUrl, notPurchaseManager]
    }
  },
  {
    path: '/translator/availability',
    name: 'translator.availability',
    component: () => import('../components/pages/freelance-translator-availablity/freelance-translator-availablity'),
    meta: {
      middleware: [translator, setUiUrl, notPurchaseManager]
    }
  },
  {
    path: '/guest/normal-booking',
    name: 'guest.normal-booking',
    component: () => import('../components/pages/guest/guest-normal-booking'),
    meta: {
      // middleware: [customer, setUiUrl, notPurchaseManager],
      layout: 'blank'
    }
  },
  // --------------------------------------------------
  // PUBLIC BOOKING ACTIONS
  // --------------------------------------------------
  {
    path: '/bookings/public/feedback',
    name: 'bookings.public.feedback',
    component: () => import('../components/pages/bookings/public/feedback'),
    meta: {
      layout: 'blur'
    }
  },

  {
    path: '/bookings/public/edit-session',
    name: 'bookings.public.edit-session',
    component: () => import('../components/pages/bookings/public/edit-session'),
    meta: {
      layout: 'blur'
    }
  },

  {
    path: '/bookings/public/accept',
    name: 'bookings.public.accept',
    component: () => import('../components/pages/bookings/public/accept'),
    meta: {
      layout: 'blur'
    }
  },

  {
    path: '/bookings/public/reject',
    name: 'bookings.public.reject',
    component: () => import('../components/pages/bookings/public/accept'),
    meta: {
      layout: 'blur'
    }
  },

  {
    path: '/bookings/public/travel-time',
    name: 'bookings.public.travel-time',
    component: () => import('../components/pages/bookings/public/travel-time'),
    meta: {
      layout: 'blur'
    }
  },

  {
    path: '/bookings/public/low-feedback',
    name: 'bookings.public.low-feedback',
    component: () => import('../components/pages/bookings/public/low-feedback'),
    meta: {
      layout: 'blur'
    }
  },

  {
    path: '/bookings/public/redo-preferences',
    name: 'bookings.public.redo-preferences',
    component: () => import('../components/pages/bookings/public/redo-preferences'),
    meta: {
      layout: 'blur'
    }
  },

  // --------------------------------------------------
  // ADMIN PANEL - EXTERNAL
  // --------------------------------------------------
  {
    path: '/admin-panel',
    component: () => import('../components/pages/booking'),
    beforeEnter() {
      window.location.href = import.meta.env.VITE_DT_ADMIN_URL
      // const appTenantConfig = getAppTenantConfig()
      // if (appTenantConfig && appTenantConfig.admin_url) {
      //   window.location.href = appTenantConfig.admin_url
      // }
    }
  },

  // --------------------------------------------------
  // FOR TRANSLATOR
  // --------------------------------------------------
  {
    path: '/for-translator',
    name: 'for-translator',
    component: () => import('../components/pages/for-translator')
  },
  {
    path: '/training-for-translator',
    name: 'training-for-translator',
    component: () => import('../components/pages/training-for-translator')
  },

  // --------------------------------------------------
  // CONTACT
  // --------------------------------------------------
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../components/pages/contact')
  },

  // --------------------------------------------------
  // PROFILE
  // --------------------------------------------------
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../components/pages/profile'),
    meta: {
      middleware: [authenticated, setUiUrl, notPurchaseManager]
    }
  },
  {
    path: '/payment-setup',
    name: 'payment-setup-page',
    component: () => import('../components/pages/payment-setup-page'),
    meta: {
      middleware: [authenticated, setUiUrl, notPurchaseManager]
    }
  },
  {
    path: '/translator/referral',
    name: 'translator.referral',
    component: () => import('../components/pages/referrals/translator/translator-referral-page'),
    meta: {
      middleware: [translator, setUiUrl]
    }
  },
  {
    path: '/my-leaves',
    name: 'my-leaves',
    component: () => import('../components/pages/my-leaves'),
    meta: {
      middleware: [translator, setUiUrl, notPurchaseManager]
    }
  },

  // --------------------------------------------------
  // AUTH
  // --------------------------------------------------

  {
    path: '/login',
    name: 'login',
    component: () => import('../components/pages/login/v2/index'),
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register-page" */ '../components/pages/register/index'),
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/choose-type',
    name: 'choose-type',
    component: () => import(/* webpackChunkName: "register-page" */ '../components/pages/register/choose-type'),
    meta: {
      layout: 'blank'
    }
  },

  {
    path: '/login/as',
    name: 'login.as',
    component: () => import('../components/pages/login/as')
  },

  {
    path: '/login/switch-user',
    name: 'login.switch-user',
    component: () => import('../components/pages/login/switch-user')
  },

  // {
  //   path: '/register',
  //   name: 'register',
  //   component: () => import('../components/pages/register/index')
  // },

  {
    path: '/logout',
    name: 'logout',
    component: () => import('../components/pages/logout')
  },

  {
    path: '/logout-all',
    name: 'logout-all',
    component: () => import('../components/pages/logout-all')
  },

  {
    path: '/password/reset/request',
    name: 'password.reset.request',
    component: () => import('../components/pages/password/reset/v2/request'),
    meta: {
      layout: 'blank'
    }
  },

  {
    path: '/passwordless-login/request',
    name: 'passwordless.login',
    component: () => import('../components/pages/password/reset/v2/passwordless-login'),
    meta: {
      layout: 'blank'
    }
  },

  {
    path: '/password/reset/verify',
    name: 'password.reset.verify',
    component: () => import('../components/pages/password/reset/verify'),
    meta: {
      layout: 'blur'
    }
  },

  // --------------------------------------------------
  // LANDING
  // --------------------------------------------------

  {
    path: '/careers',
    component: () => import('../components/pages/blog/public/Allblog'),
    beforeEnter() {
      window.open(
        '//' + 'digitaltolk.weselect.com',
        '_blank' // <- This is what makes it open in a new window.
      )
    }
  },

  // --------------------------------------------------
  // FEEDBACKS
  // --------------------------------------------------
  {
    path: '/feedbacks',
    name: 'feedbacks',
    component: () => import('../components/pages/feedbacks')
  },

  // --------------------------------------------------
  // Communication Center
  // --------------------------------------------------
  {
    path: '/survey/:id/details',
    name: 'survey.details',
    component: () => import('../components/pages/survey/Alldetails')
  },

  {
    path: '/survey/public/submit',
    name: 'survey.public.submit',
    component: () => import('../components/pages/survey/public-details'),
    meta: {
      layout: 'blur'
    }
  },

  {
    path: '/media/:slug',
    name: 'blog.details',
    component: () => import('../components/pages/blog/details')
  },
  {
    path: '/blog/view/as',
    name: 'blog.view.as',
    component: () => import('../components/pages/blog/view-as')
  },

  {
    path: '/courses/interpreter-user-training',
    name: 'course.interpreter.user.training',
    component: () => import('../components/pages/courses/InterpreterUserTraining')
  },

  {
    path: '/courses/use-of-video-interpretation',
    name: 'courses.use.of.video.interpretation',
    component: () => import('../components/pages/courses/UseOfVideoInterpretation')
  },

  {
    path: '/media',
    name: 'blogs',
    component: () => import('../components/pages/blog/index')
  },

  // Price List Page
  {
    path: '/price-lists',
    name: 'price-list-documents.index',
    component: () => import('../components/pages/price-lists/index'),
    meta: {
      middleware: [translator, notPurchaseManager]
    }
  },

  // --------------------------------------------------
  // External Pages
  // --------------------------------------------------

  {
    path: '/pages/terms-and-agreement',
    name: 'external-pages.terms-and-agreement',
    component: () => import('../components/pages/pages/terms-and-agreement')
  },

  {
    path: '/clear-cache',
    name: 'clear-cache',
    component: () => import('../components/pages/clear-cache')
  },

  {
    path: '/verbiages-clear',
    name: 'verbiages-clear',
    component: () => import('../components/pages/verbiages-clear')
  },

  {
    path: '/emails',
    name: 'emails',
    component: () => import('../components/pages/emails/index'),
    meta: {
      middleware: [authenticated, setUiUrl, notPurchaseManager]
    }
  },
  {
    path: '/email/inbox',
    name: 'email.inbox',
    component: () => import('../components/pages/emails/index'),
    meta: {
      middleware: [authenticated, setUiUrl, notPurchaseManager]
    }
  },
  {
    path: '/email/draft',
    name: 'email.draft',
    component: () => import('../components/pages/emails/index'),
    meta: {
      middleware: [authenticated, setUiUrl, notPurchaseManager]
    }
  },
  {
    path: '/email/sent',
    name: 'email.sent',
    component: () => import('../components/pages/emails/index'),
    meta: {
      middleware: [authenticated, setUiUrl, notPurchaseManager]
    }
  },
  {
    path: '/email/trash',
    name: 'email.trash',
    component: () => import('../components/pages/emails/index'),
    meta: {
      middleware: [authenticated, setUiUrl, notPurchaseManager]
    }
  },
  {
    path: '/email/important',
    name: 'email.important',
    component: () => import('../components/pages/emails/index'),
    meta: {
      middleware: [authenticated, setUiUrl, notPurchaseManager]
    }
  },
  {
    path: '/emails/:id',
    name: 'emails.details',
    component: () => import('../components/pages/emails/all-details'),
    meta: {
      middleware: [authenticated, setUiUrl, notPurchaseManager]
    }
  },
  {
    path: '/statistics/public',
    name: 'statistics.public',
    component: () => import('../components/pages/statistics/stat-index'),
    meta: {
      layout: 'blank'
    }
  },

  // --------------------------------------------------
  // passed course
  // --------------------------------------------------
  {
    path: '/passed-course',
    name: 'passed_course',
    component: () => import('../components/pages/passed-course'),
    meta: {
      middleware: [authenticated, setUiUrl, notPurchaseManager]
    }
  },

  // --------------------------------------------------
  // Customer integrations
  // --------------------------------------------------
  {
    path: '/webhooks/history',
    name: 'webhooks.history',
    component: () =>
      import(
        /* webpackChunkName: "webhooks-history-page" */ '../components/pages/customer/integrations/webhooks-history'
      ),
    meta: {
      middleware: [authenticated, setUiUrl, notPurchaseManager]
    }
  },
  {
    path: '/webhooks/details',
    name: 'webhooks.details',
    component: () =>
      import(
        /* webpackChunkName: "webhooks-details-page" */ '../components/pages/customer/integrations/webhooks-details'
      ),
    meta: {
      middleware: [authenticated, setUiUrl, notPurchaseManager]
    }
  },
  {
    path: '/integrations/settings',
    name: 'integrations.settings',
    component: () =>
      import(/* webpackChunkName: "integration-setting-page" */ '../components/pages/customer/integrations/settings'),
    meta: {
      middleware: [authenticated, setUiUrl, notPurchaseManager]
    }
  },
  {
    path: '/integrations/user-webhooks',
    name: 'integrations.user-webhooks',
    component: () =>
      import(
        /* webpackChunkName: "user-webhooks-setting-page" */ '../components/pages/customer/integrations/user-webhooks'
      ),
    meta: {
      middleware: [authenticated, setUiUrl, notPurchaseManager]
    }
  },
  {
    path: '/auth/outlook/callback',
    component: () => import('../components/partials/OutlookOAuthCallback')
  }
]

let paths = []
// noinspection EqualityComparisonWithCoercionJS
if (isMaintenanceMode == 1) {
  const maintenancePrefix = '/' + import.meta.env.VITE_MAINTENANCE_PREFIX

  paths = [
    {
      path: '/',
      component: () => import('../components/pages/maintenance'),
      meta: {
        layout: 'blank'
      }
    },
    {
      path: maintenancePrefix + '/booking',
      name: 'booking',
      component: () => import('../components/pages/booking'),
      meta: {
        middleware: [notTranslator, setUiUrl, notPurchaseManager]
      }
    },
    ...map(pathPatterns, (o) => {
      return {
        ...o,
        path: maintenancePrefix + o.path
      }
    }),
    {
      path: '/:pathMatch(.*)*',
      component: () => import('../components/pages/maintenance'),
      meta: {
        layout: 'blank'
      }
    }
  ]
} else {
  paths = [
    {
      path: '/',
      name: 'booking',
      component: () => import('../components/pages/booking'),
      meta: {
        middleware: [notTranslator, setUiUrl, notPurchaseManager]
      }
    },
    ...pathPatterns,
    {
      path: '/:pathMatch(.*)*',
      component: () => import('../components/pages/404')
    }
  ]
}

let localePaths = generateLocaleRoute(paths, ['en', 'se'])

const routes = [...paths, ...localePaths]

export const links = paths

export default routes
