/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > loadCompanyOpts (Paginated)
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import isNil from 'lodash/isNil'

export default async function (context, payload = {}) {
  context.commit('auth/setCompanyOptsLoading', true, { root: true })

  let allCompanies = []
  let nextPageUrl = `${API.COMPANIES}-get-for-ct?page=1&per_page=200`
  console.log('payload.municipality_id', payload.municipality_id)
  if (!isNil(payload.municipality_id)) {
    nextPageUrl += `&municipality_id=${payload.municipality_id}&`
  }

  try {
    while (nextPageUrl) {
      if (nextPageUrl.startsWith('http://')) {
        nextPageUrl = nextPageUrl.replace('http', 'https')
      }
      const response = await axiosInstance.get(nextPageUrl)

      if (response && response.data) {
        const { companies } = response.data.data
        const { pagination } = response.data.meta

        if (companies) {
          allCompanies = [...allCompanies, ...companies]
        }

        nextPageUrl = pagination?.links?.next || null // Get next page URL
      } else {
        nextPageUrl = null // Stop fetching if no more data
      }
    }

    // Commit the final list of companies to Vuex
    context.commit('auth/setCompanyOpts', formatResponse(allCompanies), { root: true })
  } catch (error) {
    console.error('Error loading company options:', error)
  } finally {
    context.commit('auth/setCompanyOptsLoading', false, { root: true })
  }
}

function formatResponse(companies) {
  return {
    data: {
      data: {
        companies: companies
      }
    }
  }
}
