import { createStore } from 'vuex'
import auth from '@/modules/auth/store'
import booking from '@/modules/booking/store/booking'
import bookingList from '@/modules/booking/store/booking-list'
import earning from '@/modules/statistics/store/earning'
import statistics from '@/modules/statistics/store/statistics'
import textTranslation from '@/modules/booking/store/text-translation'
import blog from '@/modules/communication/blog/store'
import email from '@/modules/email/store'
import expense from '@/modules/booking/store/expense'
import fullscreenLoader from '@/store/fullscreenLoader'
import multitenancy from '@/store/multitenancy'
import priceList from '@/modules/price-lists/store'
import translatorAvailablity from '@/modules/settings/store'
import translatorTimeSlots from '@/modules/booking/store/translator-time-slots'
import leaveRequest from '@/modules/workforce-management/store/leave-request'
import payment from '@/modules/payment/store'
import multiTenancy from '@/modules/multi-tenancy/store'
import customerIntegration from '@/modules/customer-integrations/store'
import referrals from '@/modules/referrals/store'
import tours from '@/modules/tours/store'
import users from '@/modules/users/store'
const store = createStore({
  strict: false,
  modules: {
    auth,
    multiTenancy,
    booking,
    bookingList,
    earning,
    statistics,
    textTranslation,
    blog,
    expense,
    fullscreenLoader,
    multitenancy,
    email,
    priceList,
    translatorAvailablity,
    translatorTimeSlots,
    leaveRequest,
    payment,
    customerIntegration,
    referrals,
    tours,
    users
  }
})

window.__vuexStore = store

export default store
