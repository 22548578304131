import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

export default async function (context) {
  const url = `${API.MUNICIPALITIES}`
  const method = 'GET'
  let allResults = []

  let nextPageUrl = url + '?sort=name&per_page=25' // Initial URL

  try {
    // Fetch data with pagination
    while (nextPageUrl) {
      if (nextPageUrl.startsWith('http://')) nextPageUrl = nextPageUrl.replace('http://', 'https://')

      let response = await axiosInstance.request({
        url: nextPageUrl,
        method
      })

      // Add the current page's municipalities to the result array
      allResults = allResults.concat(response.data.data.municipalities)

      // Get the next page URL from the API response
      nextPageUrl = response.data.meta.pagination.links.next || null
    }

    // Commit the results to the store
    context.commit('auth/setMunicipalities', formatResult(allResults), { root: true })
  } catch (error) {
    console.error('Error loading municipalities:', error)
    // Optionally commit an error state here
  }
}

function formatResult(municipalities) {
  return {
    municipalities
  }
}
