/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > getDeviationsSettings
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action for fetching deviations settings (e.g. "low_feedback" issues).
 *
 * @param {Object} context - Current context of the Vuex module.
 * @param {Object} payload - Additional query parameters.
 * @returns {Promise}
 */
export default function (context, payload = {}) {
  // Base URL for the request
  const baseUrl = API.BOOKING_ISSUES_SETTING

  // Combine default filters with incoming payload
  let queryParams = {
    'filter[type]': 'low_feedback',
    ...payload
  }

  // Prepare pagination
  let nextPageUrl = baseUrl
  let allResults = []

  return new Promise((resolve, reject) => {
    const loadData = async () => {
      let finalResponse = null

      try {
        // Keep fetching as long as there is a next page
        while (nextPageUrl) {
          // Ensure HTTPS if necessary
          if (nextPageUrl.startsWith('http://')) {
            nextPageUrl = nextPageUrl.replace('http://', 'https://')
          }

          const response = await axiosInstance.get(nextPageUrl, {
            params: queryParams
          })

          finalResponse = response

          // Accumulate current page data
          const currentData = response?.data?.data?.booking_issue_settings || []
          allResults = allResults.concat(currentData)

          // Check for next page
          nextPageUrl = response?.data?.meta?.pagination?.links?.next || null
        }

        // Mutate finalResponse to contain all results
        if (finalResponse) {
          finalResponse.data = finalResponse.data || {}
          finalResponse.data.data = finalResponse.data.data || {}
          finalResponse.data.data.booking_issue_settings = allResults || []
        }

        // Resolve the promise with the final data structure
        resolve(finalResponse)
      } catch (error) {
        console.error('Error fetching deviations settings:', error)
        reject(error)
      }
    }

    // Start loading data
    loadData()
  })
}
