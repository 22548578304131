import isNil from 'lodash/isNil'
import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

function loadTranslatorUnavailableTimesGeneric(context, payload = {}) {
  // Ensure we have queryParams
  payload.queryParams = !isNil(payload.queryParams) ? payload.queryParams : {}

  const url = API.TRANSLATOR_UNAVAILABLE_TIMES
  const method = 'GET'

  // Pagination setup
  let nextPageUrl = url
  let allUnavailableTimes = []

  return new Promise((resolve, reject) => {
    const loadData = async () => {
      try {
        // Loop until there's no next page
        while (nextPageUrl) {
          // Ensure HTTPS if needed
          if (nextPageUrl.startsWith('http://')) {
            nextPageUrl = nextPageUrl.replace('http://', 'https://')
          }

          // Fetch the current page
          const response = await axiosInstance.request({
            url: nextPageUrl,
            method,
            params: payload.queryParams
          })

          // Accumulate results
          const currentTimes = response?.data?.data?.translator_unvailable_times || []
          allUnavailableTimes = allUnavailableTimes.concat(currentTimes)

          // Move to the next page if it exists
          nextPageUrl = response?.data?.meta?.pagination?.links?.next || null
        }

        // Return the data in the expected structure
        const result = {
          data: {
            data: {
              translator_unvailable_times: allUnavailableTimes
            }
          }
        }

        resolve(result)
      } catch (error) {
        console.error('Error loading translator unavailable times:', error)
        reject(error)
      }
    }

    // Start loading data
    loadData()
  })
}

export default loadTranslatorUnavailableTimesGeneric
