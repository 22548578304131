import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import appStorage from '../../../../helpers/appStorage'
import isNil from 'lodash/isNil'

export default async function (context, payload = {}) {
  // If we already have languages in the store, skip fetching
  if (context.getters['recruitmentLanguageOpts'].length > 0) {
    return
  }

  // Attempt to load cached languages
  const cachedLanguages = appStorage.get(context.state.dtRecruitmentLanguagesCookieKey)
  const updatedAt = appStorage.get(context.state.dtRecruitmentLanguagesUpdatedCookieKey)
  console.log('cachedLanguages', cachedLanguages, 'updatedAt', updatedAt, 'payload.load', payload.load)
  // If we have valid cached data and aren't forcing a reload, use it
  if (!isNil(cachedLanguages) && !isNil(updatedAt) && !payload.load) {
    context.commit('setRecruitmentLanguageOpts', cachedLanguages)
    return
  }

  // Otherwise, fetch from the API with pagination
  let nextPageUrl = API.RECRUITMENT_LANGUAGES + '?per_page=40'
  let allResults = []

  try {
    while (nextPageUrl) {
      // Ensure we use HTTPS if the URL starts with http://
      if (nextPageUrl.startsWith('http://')) {
        nextPageUrl = nextPageUrl.replace('http://', 'https://')
      }

      const response = await axiosInstance.request({
        url: nextPageUrl,
        method: 'GET',
        headers: { 'X-Recruitment-Client': 1 }
      })

      // Gather current page's data
      const currentData = response?.data?.data.languages || []
      allResults = allResults.concat(currentData)

      // Check for next page
      nextPageUrl = response?.data?.meta?.pagination?.links?.next || null
    }

    // Commit the result to Vuex
    context.commit('setRecruitmentLanguageOpts', formatResults(allResults))

    // Cache the results
    appStorage.set(context.state.dtRecruitmentLanguagesCookieKey, formatResults(allResults))
    appStorage.set(context.state.dtRecruitmentLanguagesUpdatedCookieKey, context.state.newLastUpdate)
  } catch (error) {
    console.error('Error loading recruitment languages:', error)
    // Optionally handle error state here
  }
}

function formatResults(results) {
  return {
    data: {
      data: {
        languages: results
      }
    }
  }
}
