/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > loadTrainingOpts
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import isNil from 'lodash/isNil'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

export default function (context, payload = {}) {
  // Start loading
  context.commit('auth/setTrainingOptsLoading', true, { root: true })

  // Build query parameters (removing `all: true`)
  let queryParams = {
    'fields[trainings]': 'id,name',
    'sort': 'name',
    'filter[enabled]': 1
  }

  // Apply filters if provided
  if (!isNil(payload.name) && payload.name !== '') {
    queryParams['filter[name]'] = payload.name
  }
  if (!isNil(payload.id) && payload.id !== '' && payload.id !== 0) {
    queryParams['filter[id]'] = payload.id
  }

  // Initial URL for trainings
  let nextPageUrl = API.TRAININGS + '?per_page=25'

  // Accumulate all training entries
  let allTrainings = []

  return new Promise((resolve, reject) => {
    const loadData = async () => {
      let finalResponse = null

      try {
        while (nextPageUrl) {
          // Ensure HTTPS if needed
          if (nextPageUrl.startsWith('http://')) {
            nextPageUrl = nextPageUrl.replace('http://', 'https://')
          }

          // Fetch the current page
          const response = await axiosInstance.get(nextPageUrl, {
            params: queryParams
          })

          // Keep track of this response so we can mutate it later
          finalResponse = response

          // Collect current page's trainings
          const currentTrainings = response?.data?.data?.trainings || []
          allTrainings = allTrainings.concat(currentTrainings)

          // Determine if there's another page
          nextPageUrl = response?.data?.meta?.pagination?.links?.next || null
        }

        // Mutate finalResponse to include the combined trainings
        if (finalResponse) {
          finalResponse.data = finalResponse.data || {}
          finalResponse.data.data = finalResponse.data.data || {}
          finalResponse.data.data.trainings = allTrainings
        }

        // Commit to the store in the same format your mutation expects
        if (finalResponse) {
          context.commit('setTrainingOpts', finalResponse, { root: false })
        }

        // Resolve with the final response (so calling code can access it)
        resolve(finalResponse)
      } catch (error) {
        console.error('Error loading translator options:', error)
        reject(error)
      } finally {
        // End loading state
        context.commit('auth/setTrainingOptsLoading', false, { root: true })
      }
    }

    loadData()
  })
}
