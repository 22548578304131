/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > loadCustomerTypeOpts
|--------------------------------------------------------------------------
|
*/
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action to load the department opts with pagination from a Laravel backend.
 *
 * @param {object} context - Current context of the Vuex module.
 * @param {object} payload - Contains necessary values usable within the method.
 * @returns {Promise|*}
 */
export default async function (context) {
  context.commit('auth/setCustomerTypeOptsLoading', true, { root: true })

  const url = '/api/v3/core/customer-types-for-ct?page=1&per_page=25'
  const method = 'GET'
  let allResults = []

  let nextPageUrl = url // Initial URL

  try {
    // Keep fetching pages until the `next` link is null
    while (nextPageUrl) {
      if (nextPageUrl.startsWith('http://')) {
        nextPageUrl = nextPageUrl.replace('http://', 'https://')
      }

      let response = await axiosInstance.request({
        url: nextPageUrl,
        method
      })

      // Add the current page's customer types to the result array
      allResults = allResults.concat(response.data.data.customer_types)

      // Get the next page URL from the API response
      nextPageUrl = response.data.meta.pagination.links.next || null
    }

    // Commit the results to the store
    context.commit('auth/setCustomerTypeOpts', formatResponse(allResults), { root: true })
  } catch (error) {
    console.error('Error loading customer types:', error)
    // Optionally commit an error state here
  } finally {
    context.commit('auth/setCustomerTypeOptsLoading', false, { root: true })
  }
}

function formatResponse(customer_types) {
  return {
    data: {
      data: {
        customer_types: customer_types
      }
    }
  }
}
