import API from '@/modules/constants/api'
import appStorage from '../../../../helpers/appStorage'
import isNil from 'lodash/isNil'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

export default async function (context) {
  // If we already have languages in the store, no need to fetch again
  if (context.getters['deepLLanguagesOpts'].length > 0) {
    return
  }

  // Attempt to load cached languages from local storage
  const cachedLanguages = appStorage.get(context.state.dtDeepLLanguagesCookieKey)
  if (!isNil(cachedLanguages)) {
    context.commit('setDeepLLanguagesOpts', cachedLanguages)
    return
  }

  // Otherwise, fetch from the API with pagination
  let nextPageUrl = API.DEEPL_LANGUAGES
  let allResults = []

  try {
    while (nextPageUrl) {
      // Ensure HTTPS if the URL starts with "http://"
      if (nextPageUrl.startsWith('http://')) {
        nextPageUrl = nextPageUrl.replace('http://', 'https://')
      }

      const response = await axiosInstance.request({
        url: nextPageUrl,
        method: 'GET'
      })

      // Accumulate current page results
      const currentData = response?.data?.data || []
      allResults = allResults.concat(currentData)

      // Check for next page
      nextPageUrl = response?.data?.meta?.pagination?.links?.next || null
    }

    // Commit the combined results to the store and cache them
    context.commit('setDeepLLanguagesOpts', allResults)
    appStorage.set(context.state.dtDeepLLanguagesCookieKey, allResults)
  } catch (error) {
    console.error('Error loading DeepL languages:', error)
  }
}
